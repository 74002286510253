import {GET_CLIENT} from "../actions/types";

const initialState = {
    items: [],
    counter: 0
}

export default function clientsReducers(state = initialState, action) {
    switch(action.type) {
        case GET_CLIENT:
            return action.items;
        default:
            return state
    }
}
