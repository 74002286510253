import React, {Component} from "react";
import {createTheme, withStyles} from "@material-ui/core/styles";
import Select from '@material-ui/core/Select';
import MenuItem from "@material-ui/core/MenuItem";
import flagEng from "../../../static/img/flag-eng.svg";
import flagRus from "../../../static/img/flag-rus.svg";
import Grid from "@material-ui/core/Grid";
import { UserContext } from "../../../context/UserContext";
import i18n from '../../../services/i18n';

const useStyles = (theme) => ({

    selectlanguage: {
        width: 100,
        lineHeight: 1,
        margin: '7px 0px 7px 0px',
        padding: '7px 3px',
        borderRadius: 6,
        //boxShadow: 'none',
        boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.07)',
        border: 'solid 1px #dfe3e5',
        '& .MuiInputBase-input': {
            padding: 0
        },
        '&:before' : {
            borderBottom:0
        },
        '&:hover:before' : {
            borderBottom:0
        },
        '& .MuiSelect-root': {
            minHeight: 'auto',
            lineHeight: '24px',
            height: '24px',
            verticalAlign: 'middle'
        },
        '& b': {
        },
        '& :after': {
            border: 'solid 1px #dfe3e5',
        }
    }
});


class SelectLang extends Component  {
    render() {
        const {classes} = this.props;
        return <UserContext.Consumer>
            {({user, setAuthUser, setLang}) => <Select
                style={{ color: (this.props.color?this.props.color:'black')}}
                className={"selectlanguage " + classes.selectlanguage}
                defaultValue={user.getLang()}
                label="Language"
                onChange={(event) => {
                    i18n.changeLanguage(event.target.value);
                    setLang(event);
                }}
            >

                <MenuItem value={"ru"}><img  src={flagRus} align="top" alt={"РУС"} /> <b>РУС</b></MenuItem>
                <MenuItem value={"en"}><img  src={flagEng} align="top" alt={"ENG"} /> <b>ENG</b></MenuItem>
            </Select>}
        </UserContext.Consumer>
    }
}


export default withStyles(useStyles)(SelectLang)
