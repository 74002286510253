import React,{Component} from "react";
import Grid from '@material-ui/core/Grid';
import { NotificationManager } from 'react-notifications';
import { withStyles } from '@material-ui/core/styles';
import {UserContext} from "../../context/UserContext";
import Container from '@material-ui/core/Container';
import VeriflList from '../../components/VeriflList';
import {StyledTableCell, StyledTableRow, useStyles} from './styles';
import {Trans} from "react-i18next";
import Button from '@material-ui/core/Button';
import {ruRU} from '@material-ui/core/locale';
import { createTheme } from '@material-ui/core/styles';
import Chip from "@material-ui/core/Chip";
import Avatar from "@material-ui/core/Avatar";
import CheckRoundedIcon from "@material-ui/core/SvgIcon/SvgIcon";
import ClearRoundedIcon from '@material-ui/icons/ClearRounded';
import apiStore from '../../store/ApiStore';
import Waveform from '../../components/Waveform';
import SearchIcon from '@material-ui/icons/Search';
import moment from 'moment';
import 'moment/locale/ru';
import TextField from "@material-ui/core/TextField/TextField";
import Audio from "../../components/Audio";
import {millisecondsToHms} from "../../lib/helper";
import Client from "../../store/Model/Client";
import CloseIcon from '@mui/icons-material/Close';
import PrintIcon from '@mui/icons-material/Print';
import i18n from "../../services/i18n";
import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import {config} from "../../config";


// moment.locale('ru');

class Verify extends Component{

    constructor(props) {
        super(props);
        this.state = {
            tabIndex: 0,
            arClients: [],
            isShowSelectUserDialog: false,
            selectUser: null,
            page: 0,
            pageSize: 10,
            enroll: [],
            verify: []
        };
    }

    loadList = async () => {
        await apiStore.getClients(0, 100).then(data => data.data).then(data => data.map(val=>new Client(val))).then(data => this.setState({arUsers: data}));
    }

    componentDidMount() {
        this.loadList();
    }

    renderFormSelectedUser = () => {
        const {classes} = this.props;
        const currentUser = this.state.selectUser;
        //console.log("i18n.language: ", i18n.language);
        let currentUserGender = "";
        if (currentUser) {
            switch(i18n.language) {
                case 'en':
                    currentUserGender = currentUser?.client?.gender ? "Men" : "Woman";
                    break;
                case 'ru':
                    currentUserGender = currentUser?.client?.gender ? "Мужской" : "Женский";
                    break;
                default:
                    currentUserGender = currentUser?.client?.gender ? "Men" : "Woman";
            }
        }
        //console.log("currentUserGender: ", currentUserGender);
        return <Dialog
            open={this.state.isShowSelectUserDialog}
            onClose={() => {
                this.setState({isShowSelectUserDialog: false});
            }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth={'md'}
        >
            <DialogTitle id="alert-dialog-title">
                <Grid container >
                    <Grid item xs={12} md={4} lg={4} >
                        <Trans i18nKey={"User"} >{"User"}</Trans>
                    </Grid>
                    <Grid item xs={12} md={6} lg={6} >
                        <div className={classes.profile}>
                            <Trans i18nKey={"Updated"} >{"Updated"}</Trans>: <span>{
                            currentUser
                                ? currentUser.client?.updated ? moment(currentUser.client?.updated).format("YYYY-MM-DD"):'-'
                                :'-'
                        }
                                </span>
                            &nbsp;&nbsp;&nbsp;
                            <Trans i18nKey={"Created"} >{"Created"}</Trans>: <span>{
                            currentUser
                                ? currentUser.client?.created ? moment(currentUser.client?.created).format("YYYY-MM-DD"):'-'
                                :'-'
                        }
                                </span>
                        </div>
                    </Grid>
                    <Grid item xs={12} md={2} lg={2} >
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <PrintIcon
                            className={classes.icon}
                            onClick={(event) => {
                                window.print();
                            }}
                        />
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <CloseIcon
                            className={classes.icon}
                            onClick={(event) => {
                                this.setState({isShowSelectUserDialog: false});
                            }}
                        />
                    </Grid>
                </Grid>
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                </DialogContentText>
                <Grid container spacing={2} >
                    <Grid item xs={12} md={12} lg={4} >
                        <Avatar
                            className={classes.profileImage}
                            src={
                                currentUser && currentUser?.avatarUser && currentUser?.avatarUser.link
                                    ? config.BACKEND + currentUser?.avatarUser.link.replace("/var/www/files", "") : null}
                            variant={'square'}
                        />
                    </Grid>
                    <Grid item xs={12} md={12} lg={4} >
                        <div className={classes.profile}>
                            <Trans i18nKey={"LastName"} >{"LastName"}</Trans>:<br/>
                            <span> {
                                currentUser ? currentUser.client?.lastname : "-"}
                                        </span><br/>
                            <Trans i18nKey={"FirstName"} >{"FirstName"}</Trans>:<br/>
                            <span> {
                                currentUser ? currentUser.client?.firstname : "-"}
                                        </span><br/>
                            <Trans i18nKey={"MiddelName"} >{"MiddelName"}</Trans>:<br/>
                            <span> {
                                currentUser ? currentUser.client?.middlename : "-"}
                                        </span><br/>
                            <Trans i18nKey={"PlaceBirth"} >{"PlaceBirth"}</Trans>:<br/>
                            <span> {
                                currentUser ? currentUser.client?.placebirth : "-"}
                                        </span><br/>
                        </div>
                    </Grid>
                    <Grid item xs={12} md={12} lg={4} >
                        <div className={classes.profile} style={{marginLeft: 20}}>
                            <Trans i18nKey={"Gender"} >{"Gender"}</Trans>:<br/>
                            <span> {
                                currentUserGender.toString()}
                                        </span><br/>
                            <Trans i18nKey={"DateBirth"} >{"DateBirth"}</Trans>:<br/>
                            <span> {
                                currentUser ? currentUser.client?.birthday ? moment(currentUser.client?.birthday).format("YYYY-MM-DD") : "" : ""}
                                        </span><br/>
                            <Trans i18nKey={"Nationality"} >{"Nationality"}</Trans>:<br/>
                            <span> {
                                currentUser ? currentUser.client?.nationality : "-"}
                                        </span><br/>
                            <Trans i18nKey={"CitizenShip"} >{"CitizenShip"}</Trans>:<br/>
                            <span> {
                                currentUser ? currentUser.client?.citizenship : "-"}
                                        </span><br/>
                        </div>
                    </Grid>
                </Grid>

                <Grid container >
                    <Grid item xs={12} md={12} lg={4} >
                        <div className={classes.profile}>
                            <Trans i18nKey={"PassportNo"} >{"PassportNo"}</Trans>:<br/>
                            <span> {
                                currentUser ? currentUser.client?.passport_no : "-"}
                                </span><br/>
                            <Trans i18nKey={"PassportInfo"} >{"PassportInfo"}</Trans>:<br/>
                            <span> {
                                currentUser ? currentUser.client?.passport_info : "-"}
                                </span><br/>
                        </div>
                    </Grid>
                    <Grid item xs={12} md={12} lg={4} >
                        <div className={classes.profile}>
                            <Trans i18nKey={"PlaceLiving"} >{"PlaceLiving"}</Trans>:<br/>
                            <span> {
                                currentUser ? currentUser.client?.location : "-"}
                                </span><br/>
                            <Trans i18nKey={"DriverLicense"} >{"DriverLicense"}</Trans>:<br/>
                            <span> {
                                currentUser ? currentUser?.client?.driver_no : "-"}
                                </span><br/>
                            <Trans i18nKey={"Insurance"} >{"Insurance"}</Trans>:<br/>
                            <span> {
                                currentUser ? currentUser?.client?.insurance : "-"}
                                </span><br/>
                        </div>
                    </Grid>
                    <Grid item xs={12} md={12} lg={4} >
                        <div className={classes.profile} style={{marginLeft: 20}}>
                            <Trans i18nKey={"Phone"} >{"Phone"}</Trans>:<br/>
                            <span> {
                                currentUser ? currentUser?.phone : "-"}
                                </span><br/>
                            <Trans i18nKey={"Email"} >{"Email"}</Trans>:<br/>
                            <span> {
                                currentUser ? currentUser?.email : "-"}
                                </span><br/>
                            <Trans i18nKey={"Login"} >{"Login"}</Trans>:<br/>
                            <span> {
                                currentUser ? currentUser?.login : "-"}
                                </span><br/>
                        </div>
                    </Grid>
                    <Grid item xs={12} md={12} lg={12} >
                        <div className={classes.profile}>
                            <Trans i18nKey={"Info"} >{"Info"}</Trans>:<br/>
                            <span> {
                                currentUser ? currentUser.client?.info : "-"}
                                </span><br/><br/>
                        </div>
                    </Grid>
                </Grid>

            </DialogContent>
            <DialogActions>
                <Button variant="outlined"
                        onClick={() => {
                            this.setState({isShowSelectUserDialog: false});
                        }}
                        color="primary"
                >
                    <Trans i18nKey={"OK"} >{"OK"}</Trans>
                </Button>
            </DialogActions>
        </Dialog>
    };

    render() {
        let self = this;
        return <UserContext.Consumer>
            {
                ({user}) => self._render()
            }
        </UserContext.Consumer>;
    }

    _render() {
        const { classes } = this.props;

        return (
            <>
            {this.renderFormSelectedUser()}
             <div className={classes.root} style={{minHeight: "100vh"}}>
            <Container>
                    <Grid container spacing={0} className={classes.find}  >
                         {this.state.select?
                             <>
                                 <Grid container spacing={0}  >
                                     <Grid item xs={2} md={2} lg={2}  className={classes.fileinfo} >
                                         <label><Trans i18nKey={"FIO"} >{"FIO"}</Trans></label>
                                         {this.state.arUsers.filter((val) => {
                                             return val.id === this.state.select.user_u;
                                         }).map((val) => {
                                             // return val.info.fullName;
                                             return (
                                                 <Button variant="text"
                                                         onClick={(event) => {
                                                             //console.log("select: ", this.state.select);
                                                             apiStore.getClientProfileByUid(this.state.select.user_u).then(data => {
                                                                 console.log("data: ", data);
                                                                 this.setState({
                                                                     selectUser: data,
                                                                     isShowSelectUserDialog: true
                                                                 });
                                                             });
                                                         }}
                                                 >
                                                     {val.info.fullName}
                                                 </Button>
                                             )
                                         })[0]}
                                     </Grid>
                                     <Grid item xs={2} md={1} lg={1}  className={classes.fileinfo} >
                                         <label><Trans i18nKey={"Status"} >{"Status"}</Trans></label>
                                         {
                                             this.state.select.result === 'accept'
                                                 ? <Chip index={this.state.select.uid} className={'active'}
                                                         avatar={<Avatar><ClearRoundedIcon /></Avatar>}
                                                         variant="outlined" label={<Trans i18nKey={"Accepted"} >{"Accepted"}</Trans>}
                                                 />
                                                 : this.state.select.result === 'reject'
                                                 ? <Chip index={this.state.select.uid} className={'deactive'}
                                                         avatar={<Avatar><ClearRoundedIcon /></Avatar>}
                                                         variant="outlined" label={<Trans i18nKey={"Rejected"} >{"Rejected"}</Trans>}
                                                 />
                                                 : this.state.select.result === 'error'
                                                     ? <Chip index={this.state.select.uid} className={'deactive'}
                                                             avatar={<Avatar><ClearRoundedIcon /></Avatar>}
                                                             variant="outlined" label={<Trans i18nKey={"Error"} >{"Error"}</Trans>}
                                                     />
                                                 : ""
                                         }
                                     </Grid>
                                     <Grid item xs={2} md={2} lg={2}  className={classes.fileinfo} >
                                         <label><Trans i18nKey={"FileName"} >{"FileName"}</Trans></label>
                                         <Audio
                                             style={{width: 220, marginTop: -35, marginLeft: -1}}
                                             controls
                                             type={"verify"}
                                             file={ (() => {
                                                 let file = {link: null};
                                                 file.link = this.state.select.link;
                                                 file.uid = this.state.select.uid;
                                                 return file;
                                             })(this.state.select) }
                                         />
                                     </Grid>
                                     <Grid item xs={2} md={1} lg={1}  className={classes.fileinfo} >
                                         <label><Trans i18nKey={"Date"} >{"Date"}</Trans></label>
                                         {moment(this.state.select.study_time).format('DD.MM.YYYY hh:mm:ss')}
                                     </Grid>
                                     <Grid item xs={2} md={1} lg={1}  className={classes.fileinfo} >
                                         <label><Trans i18nKey={"Duration"} >{"Duration"}</Trans></label>
                                         {millisecondsToHms(this.state.select.duration)}
                                     </Grid>
                                     <Grid item xs={2} md={1} lg={1}  className={classes.fileinfo} >
                                         <label><Trans i18nKey={"FA"} >{"FA"}</Trans></label>
                                     {this.state.select.far}
                                 </Grid>
                                     <Grid item xs={2} md={1} lg={1}  className={classes.fileinfo} >
                                         <label><Trans i18nKey={"FR"} >{"FR"}</Trans></label>
                                         {this.state.select.frr}
                                     </Grid>
                                     <Grid item xs={2} md={1} lg={1}  className={classes.fileinfo} >
                                         <label><Trans i18nKey={"FAR_threshold"} >{"FAR_threshold"}</Trans></label>
                                         {this.state.select.threshold_far}
                                     </Grid>
                                     <Grid item xs={2} md={1} lg={1}  className={classes.fileinfo} >
                                         <label><Trans i18nKey={"FRR_threshold"} >{"FRR_threshold"}</Trans></label>
                                         {this.state.select.threshold_frr}
                                     </Grid>
                                     <Grid item xs={2} md={1} lg={1}  className={classes.fileinfo} >
                                         <label>
                                             <Trans i18nKey={"SNR_DB"} >{"SNR_DB"}</Trans>
                                             <CloseIcon className={classes.icon} onClick={(event) => {
                                                 this.setState({select: null});
                                             }
                                             }/>
                                         </label>
                                         {this.state.select.signal_snr}
                                     </Grid>
                                 </Grid>
                                <Grid container spacing={0} className={classes.find}  >
                                     <Grid item xs={12} md={12} lg={12} >
                                         <Waveform type={"verify"}
                                                   uid={this.state.select.uid}
                                                   startDateTime={this.state.select.study_time}
                                        />
                                    </Grid>
                                </Grid>
                             </>:
                             ''
                         }
                    </Grid>
                    <Grid container spacing={0} style={{}} >
                         <Grid item xs={12} md={12} lg={12} style={{backgroundColor: 'white',
                             paddingTop: 24,
                             paddingBottom: 54,
                             paddingLeft: 12,
                             paddingRight: 12}}
                         >
                            <VeriflList
                                onSelectUser={async (data) => {
                                    let uid = data.user_u;//data.pop();
                                    let client = [];
                                    let enrol = [];
                                    //await apiStore.getClientProfileByUid(uid).then(data => client = data);
                                    //await apiStore.getVoiceEnroll(uid).then( data => enrol = data);
                                    this.setState({select: data, enrol: enrol});
                                }}
                            />
                         </Grid>
                     </Grid>
            </Container>
        </div>
            </>
        );
    }
}

export default withStyles(useStyles)(Verify)
