import React,{Component} from "react";
import PropTypes from "prop-types";
import { Wrapper } from "./styles";
import {Link} from "react-router-dom";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Button from '@material-ui/core/Button';
import {withStyles} from "@material-ui/core/styles";
import {useStyles} from "./styles";
import SelectLang from '../../../components/shared/SelectLang/SelectLang';
import logo from '../../../static/img/logos-white.svg';
import {Trans} from "react-i18next";
import Typography from "@material-ui/core/Typography";




class AuthLayout extends Component  {
    render() {
        const {classes} = this.props;
        return <Wrapper  >
            <AppBar position="static"  className={classes.appbar} >
                <Container>
                    <Grid container spacing={0} >
                        <Grid item xs={6} md={6} lg={9} >
                            <Link to="/"><img src={logo}  alt={"logo"} style={{margin: 10}} /></Link>
                        </Grid>
                        <Grid item xs={3} md={"auto"} lg={"auto"} className={"wrapperUserPanelShort"} style={{paddingRight: 0}}  >
                            {window.location.pathname === '/register'?<Link to="/login" ><Button  variant="contained" className={"buttonregistr " + classes.buttonregistr}  > <Trans i18nKey={"SignIn"} >{"SignIn"}</Trans></Button></Link>:
                                <Link to="/register" ><Button  variant="contained" className={"buttonregistr " + classes.buttonregistr}  ><Trans i18nKey={"SignUp"} >{"SignUp"}</Trans></Button></Link>}
                        </Grid>
                        <Grid item xs={3} md={"auto"} lg={"auto"} className={"wrapperSelectLang"}  >
                            <SelectLang style={{color: 'white'}} color="white" />
                        </Grid>

                    </Grid>
                </Container>
            </AppBar>
            {this.props.children}
            <footer className={classes.footer}>
                <Container>
                    <Grid container spacing={2} >
                        <Grid item xs={2} md={"auto"} lg={"auto"} ><Trans i18nKey={"Copyright"} >{"Copyright"}</Trans></Grid>
                        <Grid item xs={1} lg={2} ></Grid>
                        <Grid item xs={2} md={"auto"} lg={"auto"} style={{textAlign:'right'}} >
                            <Link to="/privacy-policy" style={{marginRight:10}} ><Trans i18nKey={"Privacypolicy"} >{"Privacypolicy"}</Trans></Link>
                            <Link to="/user-agreement" style={{marginRight:10}} ><Trans i18nKey={"Useragreement"} >{"Useragreement"}</Trans></Link>
                            <Link to="/cookies-policy"><Trans i18nKey={"CookiesPolicy"} >{"CookiesPolicy"}</Trans></Link>
                        </Grid>
                    </Grid>
                </Container>
            </footer>
        </Wrapper>;
    };
}

AuthLayout.propTypes = {
    children: PropTypes.element.isRequired
};

export default withStyles(useStyles)(AuthLayout)
