import styled from "styled-components";

export  const useStyles = (theme) => ({
    root: {
        border: 0,
        height: 'calc(100vh - 117px)',
        width: "100%"
    },
    '& .MuiDataGrid-row:nth-child(odd)': {
        backgroundColor: '#fafbfb'
    },
});
