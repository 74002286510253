import React,{Component} from "react";
import Grid from '@material-ui/core/Grid';
import { NotificationManager } from 'react-notifications';
import { withStyles } from '@material-ui/core/styles';
import useStyles from './styles';
import {UserContext} from "../../context/UserContext";
import Typography from "@material-ui/core/Typography";
import Container from '@material-ui/core/Container';
import PieChart from '../../components/shared/PieChart/PieChart';
import BarChart from '../../components/shared/BarChart/BarChart';
import {Trans, Translation} from "react-i18next";
import apiStore from "../../store/ApiStore";

const data = [
    { name: 'Group A', value: 400 },
    { name: 'Group B', value: 300 },
    { name: 'Group C', value: 300 },
    { name: 'Group D', value: 200 },
];

class Dashboard extends Component{
    constructor(props) {
        super(props);
        this.state = {
            dataUser: [],
            dataEnroll: [],
            dataVerify: [],
            dataUserFilter: [],
            userFilterPeriod: 1,
            dataEnrollFilter: [],
            userEnrollPeriod: 1,
            dataVerifyFilter: [],
            userVerifyPeriod: 1
        }
    }

    componentDidMount(): void {
        apiStore.getUsersAnalytics().then((data) => {
            const dataUser = [];
            const keys = Object.keys(data).filter(val => val !== "total_all");
            for(const key of keys){
                dataUser.push({name: key, value: data[key]})
            }
            this.setState({dataUser: dataUser});
        });
        apiStore.getUsersAnalytics(this.state.userFilterPeriod).then((data) => {
            const dataUser = [];
            const keys = Object.keys(data).filter(val => val !== "total_all");
            for(const key of keys){
                dataUser.push({name: key, value: data[key]})
            }
            this.setState({dataUserFilter: dataUser});
        });
        apiStore.getEnrollAnalytics().then((data) => {
            const res = [];
            const keys = Object.keys(data).filter(val => val !== "quantity_all");
            for(const key of keys) {
                res.push({name: key, value: data[key]});
            }
            this.setState({dataEnroll: res});
        });
        apiStore.getEnrollAnalytics(this.state.userEnrollPeriod).then((data) => {
            const dataUser = [];
            const keys = Object.keys(data).filter(val => val !== "total_all");
            for(const key of keys){
                dataUser.push({name: key, value: data[key]})
            }
            this.setState({dataEnrollFilter: dataUser});
        });
        apiStore.getVerifyAnalytics().then((data) => {
            const res = [];
            const keys = Object.keys(data).filter(val => val !== "quantity_all");
            for(const key of keys) {
                res.push({name: key, value: data[key]});
            }
            this.setState({dataVerify: res});
        });
        apiStore.getVerifyAnalytics(this.state.userVerifyPeriod).then((data) => {
            const res = [];
            const keys = Object.keys(data).filter(val => val !== "quantity_all");
            for(const key of keys) {
                res.push({name: key, value: data[key]});
            }
            this.setState({dataVerifyFilter: res});
        });
    }

    render() {
        //selectedPatient = this.props.location.params.page

        const { classes } = this.props;
        return (<div className={classes.root} >
            <h2 style={{marginTop: 5, marginBottom: 5, marginLeft: 30}}><Trans i18nKey={"Analytics"} >{"Analytics"}</Trans></h2>
            <Container className={"dashboardPage"}>
                <Grid container spacing={3} style={{
                         marginTop: 0
                     }}>

                         <Grid item xs={12} md={6} lg={6} >
                             <div >
                                     <BarChart
                                        data={this.state.dataUser}
                                        margin={{top: 0, right: 50, left: 0, bottom: 0}}
                                        title={<Trans i18nKey={"NumberUsers"}>{"NumberUsers"}</Trans>}
                                        onChangePeriod={period => {
                                            console.log(period);
                                        }}
                                        selectPeriod={false}
                                     />
                             </div>
                         </Grid>
                         <Grid item xs={12} md={6} lg={6} >
                             <div >
                                    <BarChart
                                        data={this.state.dataUserFilter}
                                        margin={{top: 0, right: 50, left: 0, bottom: 0}}
                                        title={<Trans i18nKey={"NumberUsersForTime"}>{"NumberUsersForTime"}</Trans>}
                                        onChangePeriod={period => {
                                            apiStore.getUsersAnalytics(period.target.value).then((data) => {
                                                const dataUser = [];
                                                const keys = Object.keys(data).filter(val => val !== "total_all");
                                                for (const key of keys) {
                                                 dataUser.push({name: key, value: data[key]})
                                                }
                                                this.setState({dataUserFilter: dataUser, userFilterPeriod: period.target.value});
                                            });
                                        }}
                                        period={this.state.userFilterPeriod}
                                        selectPeriod={true}
                                    />
                             </div>
                         </Grid>

                         <Grid item xs={12} md={6} lg={6} >
                             <div >
                                 <BarChart
                                    data={this.state.dataEnroll}
                                    margin={{top: 0, right: 50, left: 0, bottom: 0}}
                                    title={<Trans i18nKey={"EnrollnAlytics"}>{"EnrollnAlytics"}</Trans>}
                                    onChangePeriod={period => {
                                        console.log(period);
                                    }}
                                 />
                             </div>
                         </Grid>

                         <Grid item xs={12} md={6} lg={6} >
                             <div >
                                 <BarChart
                                    data={this.state.dataEnrollFilter}
                                    margin={{top: 0, right: 50, left: 0, bottom: 0}}
                                    title={<Trans i18nKey={"EnrollnAlyticsForTime"}>{"EnrollnAlyticsForTime"}</Trans>}
                                    onChangePeriod={period => {
                                        apiStore.getEnrollAnalytics(period.target.value).then((data) => {
                                            const dataRes = [];
                                            const keys = Object.keys(data).filter(val => val !== "total_all");
                                            for (const key of keys) {
                                                dataRes.push({name: key, value: data[key]})
                                            }
                                            this.setState({dataEnrollFilter: dataRes, userEnrollPeriod: period.target.value});
                                        });
                                        }}
                                        period={this.state.userEnrollPeriod}
                                        selectPeriod={true}
                                />
                             </div>
                         </Grid>

                         <Grid item xs={12} md={6} lg={6} >
                             <div >
                                 <BarChart
                                     data={this.state.dataVerify}
                                     margin={{ top: 0, right: 50, left: 0, bottom: 0 }}
                                     title={<Trans i18nKey={"VerifynAlytics"} >{"VerifynAlytics"}</Trans>}
                                     onChangePeriod={period => {
                                         console.log(period);
                                     }}
                                 >
                                 </BarChart>
                             </div>
                         </Grid>

                         <Grid item xs={12} md={6} lg={6} >
                             <div >
                                 <BarChart
                                     data={this.state.dataVerifyFilter}
                                     margin={{ top: 0, right: 50, left: 0, bottom: 0 }}
                                     title={<Trans i18nKey={"VerifynAlyticsForTime"} >{"VerifynAlyticsForTime"}</Trans>}
                                     onChangePeriod={period => {
                                         console.log("period: ", period.target.value);
                                         apiStore.getVerifyAnalytics(period.target.value).then((data) => {
                                             const dataRes = [];
                                             const keys = Object.keys(data).filter(val => val !== "total_all");
                                             for(const key of keys){
                                                 dataRes.push({name: key, value: data[key]})
                                             }
                                             this.setState({dataVerifyFilter: dataRes, userVerifyPeriod: period.target.value});
                                         });
                                     }}
                                     period={this.state.userVerifyPeriod}
                                     selectPeriod={true}
                                 >
                                 </BarChart>
                             </div>
                         </Grid>
                         <Grid item xs={12} md={6} lg={6} >
                             <div style={{overflow:'hidden'}}>
                                 <Typography variant={"h4"} style={{float: "left", paddingBottom: 10, marginBottom: 20, fontWeight: "700", lineHeight: "1.5em", verticalAlign: "middle", borderBottom: 'solid #E4E4E4 1px'}}>

                                 </Typography>
                             </div>


                         </Grid>
                </Grid>
            </Container>
        </div>);
    }
}

export default withStyles(useStyles)(Dashboard)
