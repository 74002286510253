import React,{Component} from "react";
import SettingsForm from "../../components/shared/SettingsForm/SettingsForm"
import Grid from "@material-ui/core/Grid";
import CssBaseline from "@material-ui/core/CssBaseline";
import Paper from "@material-ui/core/Paper";
import AddRegistrForm from "../../components/shared/AddRegistrForm/AddRegistrForm";
import {withStyles} from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";


const styles = (theme) => ({
    root: {
        minHeight: '100vh',
        position: 'relative',
        marginTop: 50
    },
    formContainer: {
        boxShadow: 'none'
    }
});

class Settings extends Component{
    componentDidMount() {
        // console.log(this.props);
    }


    render() {
        const {classes} = this.props;
        return <Container>
            <Grid container component="main" className={classes.root}>
            <CssBaseline/>
                <Grid item xs={1} sm={2} md={2} lg={2}></Grid>
                <Grid item xs={10} sm={8} md={8} lg={8} component={Paper} elevation={6} square className={classes.formContainer}>
                    <SettingsForm

                    />
                </Grid>
                <Grid item xs={1} sm={2} md={2} lg={2}></Grid>

        </Grid></Container>;
    }
}

export default withStyles(styles, {withTheme: true})(Settings);
