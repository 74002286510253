import styled from "styled-components";

export  const useStyles = (theme) => ({
    root: {

    },
    find: {
        padding: '30px 0px 0px 10px'
    },
    findInput: {
        width: '100%',
        marginTop: 10,
        backgroundColor: 'white',
        '& input' : {
            padding: 18
        }
    },
    findButton: {
        width: '90%',
        marginLeft: '5%',
        backgroundColor: '#81b346',
        marginTop: 10,
        color: 'white',
        lineHeight: '40px'
    },
});
