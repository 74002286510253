import React, { Component } from 'react';
import differenceInYears from "date-fns/differenceInYears";
import DialogContent from "@material-ui/core/DialogContent";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import TextFieldValidator from "../TextFieldValidator";
import userStore from "../../../store/UserStore";
import {withSnackbar} from "notistack";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import DateFnsUtils from '@date-io/date-fns';
import {MuiPickersUtilsProvider, KeyboardTimePicker, KeyboardDatePicker} from '@material-ui/pickers';
import TextField from "@material-ui/core/TextField";
import {createTheme, withStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import {Link} from "react-router-dom";
import AdornmentPasswordField from "../../LoginForm/AdornmentPasswordField";
import Grid from "@material-ui/core/Grid";
import DoneIcon from '@material-ui/icons/Done';
import PriorityHighIcon from '@material-ui/icons/PriorityHigh';
import {NotificationManager} from 'react-notifications';
import { useTranslation, Trans } from 'react-i18next';

const styles = (theme) => ({
	root: {

		position: 'relative'
	},
	mail: {
		color: '#117dce',
		lineHeight: '34px'
	},
	steps: {
		marginTop: 10,
		marginBottom: 30,
		fontFamily: 'Roboto',
		fontSize: 16,
		lineHeight: 1.71,
		color: '#828d99'
	},
	active: {
		color: '#304156',
		'& .round' : {
			backgroundColor: '#0c5891',
			color: '#FFF'
		}
	},
	round: {
		borderRadius: 18,
		fontFamily: 'Roboto',
		fontSize: 10,
		padding: 12,
		fontWeight: 'bold',
		lineHeight: 1.6,
		textAlign: 'center',
		color: '#828d99',
		backgroundColor: '#e3eaf0',
		marginRight: 20
	},
	schoolnumber: {
		display: 'block',
		border: 'solid #c4c4c4 1px',
		borderRadius: 4,
		padding: '18.5px 14px',
		width: '100%',
		'& input:first-child': {
			float: 'left',
			width: '48%',
			borderRight: 'solid #c4c4c4 1px',
		},
		'& input:last-child': {
			float: 'right',
			width: '48%'
		}
	},
	birthday: {
		border: 'solid #c4c4c4 1px',
		borderRadius: 4,
		paddingLeft: 10,
		marginBottom: 25,
		width: '100%',
		'&>div':{
			margin: 0,

		},
		'& input': {
			width: '100%'
		},
		'& .MuiInput-formControl': {
			margin: '9px 0',
			width: '100%'
		},
		'& .MuiInput-underline:after':{
			border:0
		},
		'& .MuiInput-underline:before':{
			border:0
		},
		'& .MuiInput-underline:hover:after':{
			border:0
		}
	},
	paper: {
		marginTop: 50,
		backgroundColor: 'white',
		borderRadius: 6,
		'& p': {
			color: '#304156',
			fontFamily: 'Roboto',
			fontSize: 14
		},
		'& .MuiGrid-spacing-xs-2 > .MuiGrid-item' : {
			padding: 4,
		},
	},
	formContainer: {
		boxShadow: 'none'
	},
	header: {
		textAlign: 'center',
		padding: '15px 0 15px 0',
		fontWeight: 600,
		color: '#304156',
		fontFamily: 'Raleway'
	},
	pHeader: {

		marginBottom: 20,
		fontFamily: 'Inter',
		fontSize: 20,
		lineHeight: 4,
		textAlign: 'center',
		color: '#898A9C'
	},
	pFooter:{

	},
	textField: {
		marginBottom: 40
	},
	submit: {
		width: '100%',
		height: 64,
		background: '#1565D8',
		borderRadius: 6,
		marginTop: 40,
		marginBottom: 40
	},
	buttonregist: {
		cursor: 'pointer',
		color: '#1565D8'
	},
	ringSuccess: {
		width: 100,
		height: 100,
		borderRadius: 50,
		backgroundColor: '#eff4f5',
		color: '#81b346',
		margin: 'auto',
		'& .MuiSvgIcon-root': {
			marginTop: 30
		}
	},
	ringError: {
		width: 100,
		height: 100,
		borderRadius: 50,
		backgroundColor: '#eff4f5',
		color: '#fecf33',
		margin: 'auto',
		'& .MuiSvgIcon-root': {
			marginTop: 30
		}
	}
});

class AddRegistrForm extends Component {
	state = {
		step : 1,
		errorRegistr: [],
		confirmIsSuccess: null,
		info: {
			firstname: null,
			lastname: null,
			country: null,
			email: null,
			organization: null,
			fieldactivity: null,
			workposition: null,
			password: null,
			passwordrepeat: null,
			login: null,
			phone: null,
			pin: null
		},
		errors: {
			firstname: null,
			lastname: null,
			country: null,
			email: null,
			organization: null,
			fieldactivity: null,
			workposition: null,
			password: null,
			passwordrepeat: null,
			login: null,
			phone: null
		}
	};

	handleSubmit = (e) => {
		e.preventDefault();

		if(!this.formValid(e)) {
			if(!this.state.info.password){
				this.setState({errorRegistr: {
						'password' : <Trans i18nKey={"RegistrationErrorPasswordEmpty"} >{"RegistrationErrorPasswordEmpty"}</Trans>
					}});
				return false;
			}
			if(this.state.info.password !== this.state.info.passwordrepeat){
				this.setState({errorRegistr: {
						'password' : <Trans i18nKey={"RegistrationErrorPasswordNotMatch"} >{"RegistrationErrorPasswordNotMatch"}</Trans>
					}});
				return false;
			}

		}

		let regFormData = {
			data: this.state.info
		};
		//console.log(this.state.info);
		//console.log(regFormData);
		userStore.registrUser(regFormData.data)
			.then((result) => {
				if (result.statusCode>205) {
					const mess = <Trans i18nKey={"RegistrationError"} >{"RegistrationError"}</Trans>;
					NotificationManager.error(mess, <Trans i18nKey={"Error"} >{"Error"}</Trans>);
					let error = [];

					result.message.map((val,index) => {
						let arError = [];
						let key = null;
						let value = null;
						// console.log("val: " + val);
						if(val.search(':')) {
							val = val.replace('`', '').replace("'", '').replace("'", '').replace("'", '')
								.replace('`', '').replace('`', '').replace('`', '');
							 // console.log("val1: " + val);

							if (val.search('user.login') === 0) {
								key = 'login';
								value = <Trans i18nKey={"RegistrationErrorLogin"} >{"RegistrationErrorLogin"}</Trans>;
							} else if (val.search('login is used already, try another') === 0) {
								key = 'login';
								value = 'login is used already, try another';
							} else if (val.search('user.email') === 0) {
								key = 'email';
								value = <Trans i18nKey={"RegistrationErrorEmail"} >{"RegistrationErrorEmail"}</Trans>;
							} else if (val.search('email is used already, try another') === 0) {
								key = 'email';
								value = 'This email is used already, try another';
							} else if (val.search('user.phone') === 0) {
								key = 'phone';
								value = <Trans i18nKey={"RegistrationErrorPhone"} >{"RegistrationErrorPhone"}</Trans>;
							} else if (val.search('phone is used already, try another') === 0) {
								key = 'phone';
								value = 'phone is used already, try another';
							} else if (val.search('manager.firstname') === 0) {
								key = 'firstname';
								value = <Trans i18nKey={"RegistrationErrorFirstname"} >{"RegistrationErrorFirstname"}</Trans>;
							} else if (val.search('manager.lastname') === 0) {
								key = 'lastname';
								value = <Trans i18nKey={"RegistrationErrorLastname"} >{"RegistrationErrorLastname"}</Trans>;
							} else if (val.search('manager.organization') === 0) {
								key = 'organization';
								value = <Trans i18nKey={"RegistrationErrorOrganization"} >{"RegistrationErrorOrganization"}</Trans>;
							} else if (val.search('manager.fieldactivity') === 0) {
								key = 'fieldactivity';
								value = <Trans i18nKey={"RegistrationErrorFieldactivity"} >{"RegistrationErrorFieldactivity"}</Trans>;
							} else if (val.search('manager.workposition') === 0) {
								key = 'workposition';
								value = <Trans i18nKey={"RegistrationErrorWorkposition"} >{"RegistrationErrorWorkposition"}</Trans>;
							} else {
								[key, value] = val.split(':');
							}
						}else {
							// console.log("val2: " + val);
							arError = val.split(' ');
							key = arError.splice(0, 1)[0];
							value = arError.join(' ');
							if (val === 'This email is used already, try another') {
								key = 'email';
								value = 'This email is used already, try another';
							}
							if (val === val.search('Email server has problems')) {
								key = 'email';
								value = val;
							}
						}
						error[key.replace('user.', '').replace('manager.', '')] = <Trans i18nKey={value} >{value}</Trans>;
						return val;
					});
					this.setState({errorRegistr: error});
				} else {
					const mess = <Trans i18nKey={"RegistrationSuccessful"} >{"RegistrationSuccessful"}</Trans>;
					NotificationManager.success(mess);
					this.setState({step: 2});
					//this.props.updateUserData();
					//this.props.closeDialog();
				}
			}).catch((result) => {
				console.log(result);
		});


	};

	textChange = (event) => {
		let val = this.state.info;
		val[event.target.name] = event.target.value;
		this.setState(val);
	};

	formValid = (event) => {
		let valid = true;
		let checkInput = [
			'firstname',
			'lastname',
			'email',
			'organization',
			'fieldactivity',
			'workposition',
			'password',
			'passwordrepeat',
			'login'
		];
		let errors = [];
		for(let key of checkInput){
			if(!this.state.info[key]){
				valid = false;
				errors[key] = true;
			}else{
				errors[key] = false;
			}
		}
		this.setState({errors: errors});
		//console.log(this.state);
		//console.log(valid);
		return valid;
	};

	render() {
		const {classes} = this.props;
		return (
			<div className={classes.paper} >
				<Typography component="h2" variant="h3" className={classes.header} color={""}>
					<Trans i18nKey={"Registration"} >Registration</Trans>
				</Typography>
				<Grid container className={classes.steps} >
					<Grid item xs={"auto"} md={3} lg={2}  />
					<Grid item xs={12} sm={12} md={2} lg={3}  elevation={6} square className={this.state.step === 1 ? classes.active : ''} >
						<span onClick={(event) => this.setState({step:1})} ><span className={classes.round}>01</span> <Trans i18nKey={"Registration"} >Registration</Trans></span>
					</Grid>
					<Grid item xs={12} sm={12} md={2} lg={3}  elevation={6} square style={{textAlign:'left'}}  className={this.state.step === 2 ? classes.active : ''}  >
						<span className={classes.round}>02</span> <Trans i18nKey={"Confirmation"} >Confirmation</Trans>
					</Grid>
					<Grid item xs={12} sm={12} md={2} lg={2}  elevation={6} square style={{textAlign:'left'}}  className={this.state.step === 3 ? classes.active : ''}  >
						<span className={classes.round}>03</span> <Trans i18nKey={"Activation"} >Activation</Trans>
					</Grid>
					<Grid item xs={"auto"} md={3} lg={3}  />
				</Grid>
				<Grid container >
					<Grid item xs={"auto"} md={1} lg={1}  />
					<Grid item xs={12} sm={12} md={10} lg={10}  elevation={6} square >
						<form  onSubmit={this.handleSubmit}>

							<div style={{display:this.state.step === 1 ? 'block' : 'none'}} >

								<div style={{alignSelf: "center"}} >
									<div className="fio" style={{marginBottom: 40}}>
										<Grid container spacing={2} >
											<Grid item xs={12} sm={12} md={6} lg={3} spacing={6}  >
												<div style={{marginBottom: 15}}><b><Trans i18nKey={"Login"} >Login</Trans>*</b></div>
												<TextField
													error={this.state.errorRegistr['login']?true:false}
													helperText={this.state.errorRegistr['login']}

													required
													onChange={this.textChange}
													value={this.state.login}
													style={{marginBottom: 15}}
													variant="outlined"
													id = "login"
													name = "login"
													label = {<Trans i18nKey={"Login"} >Login</Trans>}
													type = "text"
													fullWidth
												/>
											</Grid>
											<Grid item xs={12} sm={12} md={6} lg={3} spacing={6}  >
												<div style={{marginBottom: 15}}><b><Trans i18nKey={"Password"} >Password</Trans>*</b></div>
												<AdornmentPasswordField
													error={this.state.errorRegistr['password']?true:false}
													helperText={this.state.errorRegistr['password']}

													required
													name="password"
													onChange={this.textChange}
													style={{width:'100%', marginBottom: 25}}
												/>

											</Grid>
											<Grid item xs={12} sm={12} md={6} lg={3} spacing={6} >
												<div style={{marginBottom: 15}}><b><Trans i18nKey={"RepeatPassword"} >RepeatPassword</Trans>*</b></div>
												<AdornmentPasswordField
													error={this.state.errorRegistr['passwordrepeat']?true:false}
													helperText={this.state.errorRegistr['passwordrepeat']}

													required
													name="passwordrepeat"
													onChange={this.textChange}
													style={{width:'100%'}}
												/>
											</Grid>
											<Grid item xs={12} sm={12} md={6} lg={3}  spacing={5} square >
												<div style={{marginBottom: 15}}><b><Trans i18nKey={"WorkEmail"} >WorkEmail</Trans>*</b></div>
												<TextField
													error={this.state.errorRegistr['email']?true:false}
													helperText={this.state.errorRegistr['email']}

													required
													onChange={this.textChange}
													value={this.state.email}
													style={{marginBottom: 15}}
													variant="outlined"
													id = "email"
													name = "email"
													label = {<Trans i18nKey={"WorkEmail"} >WorkEmail</Trans>}
													type = "text"
													fullWidth
												/>
											</Grid>
										</Grid>
										<Grid container  spacing={2} >
											<Grid item xs={12} sm={12} md={6} lg={4}  spacing={5} square >
												<div style={{marginBottom: 15}}><b><Trans i18nKey={"Name"} >Name</Trans>*</b></div>
												<TextField
													error={this.state.errorRegistr['firstname']?true:false}
													helperText={this.state.errorRegistr['firstname']}

													required
													onChange={this.textChange}
													value={this.state.firstname}
													style={{marginBottom: 15}}
													variant="outlined"
													id = "firstname"
													name = "firstname"
													label = {<Trans i18nKey={"Name"} >Name</Trans>}
													type = "text"
													fullWidth
												/>
											</Grid>
											<Grid item xs={12} sm={12} md={6} lg={4}  spacing={5} square >
												<div style={{marginBottom: 15}}><b><Trans i18nKey={"LastName"} >LastName</Trans>*</b></div>
												<TextField
													error={this.state.errorRegistr['lastname']?true:false}
													helperText={this.state.errorRegistr['lastname']}

													required
													onChange={this.textChange}
													value={this.state.lastname}
													style={{marginBottom: 15}}
													variant="outlined"
													id = "lastname"
													name = "lastname"
													label = {<Trans i18nKey={"LastName"} >LastName</Trans>}
													type = "text"
													fullWidth
												/>
											</Grid>
											<Grid item xs={12} sm={12} md={6} lg={4}  spacing={5} square >
												<div style={{marginBottom: 15}}><b><Trans i18nKey={"Phone"} >Phone</Trans>*</b></div>
												<TextField
													error={this.state.errorRegistr['phone']?true:false}
													helperText={this.state.errorRegistr['phone']}

													required
													onChange={this.textChange}
													value={this.state.phone}
													style={{marginBottom: 15}}
													variant="outlined"
													id = "phone"
													name = "phone"
													label = {<Trans i18nKey={"Phone"} >Phone</Trans>}
													type = "text"
													fullWidth
												/>
											</Grid>
										</Grid>

										<Grid container  spacing={2} >
											<Grid item xs={12} sm={12} md={6} lg={3}  spacing={5} square >
												<div style={{marginBottom: 15}}><b><Trans i18nKey={"Country"} >Country</Trans></b></div>
												<TextField
													error={this.state.errorRegistr['country']?true:false}
													helperText={this.state.errorRegistr['country']}


													onChange={this.textChange}
													value={this.state.country}
													style={{marginBottom: 15}}
													variant="outlined"
													id = "country"
													name = "country"
													label = {<Trans i18nKey={"Country"} >Country</Trans>}
													type = "text"
													fullWidth
												/>
											</Grid>
											<Grid item xs={12} sm={12} md={6} lg={3}  spacing={5} square >
												<div style={{marginBottom: 15}}><b><Trans i18nKey={"Organization"} >Organization</Trans>*</b></div>
												<TextField
													error={this.state.errorRegistr['organization']?true:false}
													helperText={this.state.errorRegistr['organization']}

													required
													onChange={this.textChange}
													value={this.state.organization}
													style={{marginBottom: 15}}
													variant="outlined"
													id = "organization"
													name = "organization"
													label = {<Trans i18nKey={"Organization"} >Organization</Trans>}
													type = "text"
													fullWidth
												/>
											</Grid>

											<Grid item xs={12} sm={12} md={6} lg={3}  spacing={5} square >
												<div style={{marginBottom: 15}}><b><Trans i18nKey={"FieldOfActivity"} >FieldOfActivity</Trans>*</b></div>
												<TextField
													error={this.state.errorRegistr['fieldactivity']?true:false}
													helperText={this.state.errorRegistr['fieldactivity']}

													required
													onChange={this.textChange}
													value={this.state.fieldactivity}
													style={{marginBottom: 15}}
													variant="outlined"
													id = "fieldactivity"
													name = "fieldactivity"
													label = {<Trans i18nKey={"FieldOfActivity"} >FieldOfActivity</Trans>}
													type = "text"
													fullWidth
												/>
											</Grid>
											<Grid item xs={12} sm={12} md={6} lg={3}  spacing={5} square >
												<div style={{marginBottom: 15}}><b><Trans i18nKey={"Position"} >Position</Trans>*</b></div>
												<TextField
													error={this.state.errorRegistr['workposition']?true:false}
													helperText={this.state.errorRegistr['workposition']}

													required
													onChange={this.textChange}
													value={this.state.workposition}
													style={{marginBottom: 15}}
													variant="outlined"
													id = "workposition"
													name = "workposition"
													label = {<Trans i18nKey={"Position"} >Position</Trans>}
													type = "text"
													fullWidth
												/>
											</Grid>
										</Grid>
									</div>
								</div>
								<Button variant={"contained"}  type="submit" style={{background: '#f18c47',borderRadius: 6, color: 'white', width: '100%', lineHeight: '3em', marginBottom: 25}}  >
									<Trans i18nKey={"SignUp"} >SignUp</Trans>
								</Button>
								<Checkbox
									required
									defaultChecked
									sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}
								/>
								<Trans i18nKey={"ConfirmPersonalData"} >ConfirmPersonalData</Trans>
							</div>

						</form>
							<div style={{display:this.state.step === 2 ? 'block' : 'none'}} >
								<div style={{alignSelf: "center"}} >
									<div className="fio" style={{marginBottom: 40}}>
										<p>
											<Trans i18nKey={"PincodeEmail"} >PincodeEmail</Trans>:
										</p>
										<p>
											<Link to="mailto:{this.state.info.email}" className={classes.mail} >{this.state.info.email}</Link>
										</p>
										<div style={{marginBottom: 15, marginTop: 30}}><b><Trans i18nKey={"PinCode"} >PinCode</Trans>:</b></div>
										<TextField
											required
											onChange={this.textChange}
											value={this.state.info.pin}
											style={{marginBottom: 15}}
											variant="outlined"
											id = "pin"
											name = "pin"
											label = {<Trans i18nKey={"PinCode"} >PinCode</Trans>}
											type = "text"
											fullWidth
										/>
									</div>
								</div>
								<Button variant={"contained"}  type="button" onClick={(event) => {

									userStore.userActivate(this.state.info.pin).then((result)=>{
										if (result.statusCode * 1 > 205) {
											this.setState({step: 3,confirmIsSuccess: false});
										}else{
											this.setState({step: 3,confirmIsSuccess: true});
										}
									});

								}} style={{background: '#f18c47',borderRadius: 6, color: 'white', width: '100%', lineHeight: '3em', marginBottom: 25}}  >
									<Trans i18nKey={"Confirm"} >Confirm</Trans>
								</Button>
							</div>
							<div style={{display:this.state.step === 3 ? 'block':'none', textAlign: 'center'}} >
								{
									this.state.confirmIsSuccess?
									<>
										<div style={{alignSelf: "center"}} >
											<div className={classes.ringSuccess} style={{marginBottom: 40}}>
												<DoneIcon fontSize="large" />

											</div>
											<p style={{marginBottom: 80, color: '##304156'}} ><Trans i18nKey={"AccountActivate"} >AccountActivate</Trans></p>
										</div>
										<center>
											<Link to="/login" >
												<Button variant={"contained"}   style={{background: '#2277b7',borderRadius: 6, color: 'white', lineHeight: '3em', marginBottom: 25, padding: '5px 150px'}}  >
													<Trans i18nKey={"SignIn"} >{"SignIn"}</Trans>
												</Button>
											</Link>
										</center>
									</>:
									<>
										<div style={{alignSelf: "center"}} >
											<div className={classes.ringError} style={{marginBottom: 40}}>
												<PriorityHighIcon fontSize="large" />

											</div>
											<p style={{marginBottom: 80, color: '##304156'}} ><Trans i18nKey={"PincodeError"} >Pincode Error</Trans></p>
										</div>
										<center>
												<Button variant={"contained"}   style={{background: 'white', border: 'solid 1px #dfe3e5', boxShadow: 'none', color: '#304156',borderRadius: 6,textTransform: 'none', lineHeight: '3em', marginBottom: 25, padding: '5px 150px'}} onClick={(event) => this.setState({step: 2})} >
													<Trans i18nKey={"Back"} >Back</Trans>
												</Button>
										</center>
									</>
								}

							</div>
							<center style={{color: '#1565D8', marginBottom: 45, marginTop: 45}}><Trans i18nKey={"IfExistAccauntStart"} >IfExistAccauntStart</Trans> <Link
								type="button"
								variant="contained"
								color="default"
								className={classes.buttonregist}
								style={{textDecoration: 'underline'}}
								to="/login"
							><Trans i18nKey={"Login"} >Login</Trans></Link> <Trans i18nKey={"IfExistAccauntFinish"} >IfExistAccauntFinish</Trans></center>

					</Grid>
					<Grid item xs={"auto"} md={1} lg={1} >

					</Grid>
				</Grid>

			</div>
		);
	}
}


export default withStyles(styles, {withTheme: true})(AddRegistrForm);
