import React from "react";
import userStore from '../store/UserStore';
export const user = userStore;


export const UserContext = React.createContext({
    user: user,
    setAuthUser: () => {},
    setLang: (event) => { console.log(event.target.value)},
});
