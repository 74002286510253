const useStyles = (theme) => ({
    root: {

        padding: "24px 43px 24px 41px;",
        borderRadius: 4,
        boxShadow: "-2px 0 10px 0 rgba(0, 0, 0, 0.1);",
        backgroundColor: "#fff",
        width: "100%",
        position: "relative"
    },
    h4: {
        fontFamily: "Roboto",
        fontSize: 18,
        fontWeight: "normal",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: 1.33,
        letterSpacing: "normal",
        marginBottom: 20
    },
    statitem: {
        borderBottom: "dashed #a1b3c6 1px",
        overflow: "hidden",
        paddingBottom: 5,
        paddingTop: 5,
        lineHeight: "32px",
        "&:nth-child(3)" :{
            borderBottom: 0
        },
    },

    item1: {
        float:"left",
        fontFamily: "Roboto",
        fontSize: 10,
        fontWeight: "normal",
        fontStretch: "normal",
        fontStyle: "normal",
        letterSpacing: 1,
        color: "#828d99",
        textTransform: "uppercase",
        width: "30%"
    },
    item2: {
        float:"left",
        color: "#304156",
        fontFamily: "Roboto",
        fontSize: 14,
        width: "20%"
    },
    item3: {
        float:"left",
        width: "50%"
    },
    bar: {
        width: "100%",
        backgroundColor: "#4685b3",
        borderRadius: 4,
        display: "block",
        padding: 10,
        barderBottom: "solid #a1b3c6 1px",
        marginTop: 6,
    },
    bar_accept: {
        width: "100%",
        backgroundColor: "#72b52e",
        borderRadius: 4,
        display: "block",
        padding: 10,
        marginTop: 6,
    },
    bar_notactive: {
        width: "100%",
        backgroundColor: "#dad4cc",
        borderRadius: 4,
        display: "block",
        padding: 10,
        marginTop: 6,
    },
    bar_reject: {
        width: "100%",
        backgroundColor: "#f65652",
        borderRadius: 4,
        display: "block",
        padding: 10,
        marginTop: 6,
    },
    bar_error: {
        width: "100%",
        backgroundColor: "#b5d9ee",
        borderRadius: 4,
        display: "block",
        padding: 10,
        marginTop: 6,
    },
    formControl: {
        position: "absolute",
        right: 40,
        top: 10,
        width: 140
    }
});
export default useStyles;
