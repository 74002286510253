import {GET_ENROLLS} from "../actions/types";

const initialState = {
    items: [],
    counter: 0
}

export default function enrollReducers(state = initialState, action) {
    switch(action.type) {
        case GET_ENROLLS:
            return action.items;
        default:
            return state
    }
}
