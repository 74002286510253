import React,{Component} from "react";
import AddRegistrForm from "../../components/shared/AddRegistrForm/AddRegistrForm";
import {Dialog} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import CssBaseline from "@material-ui/core/CssBaseline";
import Paper from "@material-ui/core/Paper";
import {createTheme, ThemeProvider, withStyles} from '@material-ui/core/styles';

const styles = (theme) => ({
    root: {
        height: '100vh',
        position: 'relative'
    },
    paper: {
        position: 'absolute',
        top: '50%',
        marginTop: -250
    },
    formContainer: {
        boxShadow: 'none'
    },
    pHeader: {
        borderBottom: '1px solid #E4E4E4',
        marginBottom: 20,
        fontFamily: 'Inter',
        fontSize: 20,
        lineHeight: 4,
        textAlign: 'center',
        color: '#898A9C'
    },
    textField: {
        marginBottom: 40
    },
    submit: {
        width: '100%',
        height: 64,
        background: '#1565D8',
        borderRadius: 6,
        marginTop: 40,
        marginBottom: 40
    },
    buttonregist: {
        cursor: 'pointer',
        color: '#1565D8'
    }
});

class Profile extends Component{
    state = {
        step : 1,
    }

    updateStep = (step) => {
        this.setState({step: step});
    }

    render() {
        const theme = createTheme({
            palette: {
                primary: {
                    main: "#00978C",
                },
            },

            overrides: {
                MuiOutlinedInput: {
                    root: {

                    }
                },
            }
        });
        const {classes} = this.props;
        return <Grid container component="main" className={classes.root}>
            <CssBaseline/>
            <Grid item xs={"auto"} md={3} lg={4} style={{textAlign: 'right', paddingTop: '5%', paddingRight: '5%', color: '#898A9C'}} >
                <span style={{display:this.state.step*1 === 2?'block':'none',cursor: 'pointer'}} onClick={(event => {
                    this.setState({step: 1});
                })} >&lt; Назад</span>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={4} component={Paper} elevation={6} square className={classes.formContainer}>
        <AddRegistrForm
            updateStep={this.updateStep}
            step={this.state.step}
        />					</Grid>
            <Grid item xs={"auto"} md={3} lg={4} style={{textAlign: 'left', paddingTop: '5%', paddingLeft: '5%', color: '#898A9C'}}>
                Шаг {this.state.step}/2
            </Grid>
        </Grid>;
    }
}

export default withStyles(styles, {withTheme: true})(Profile);
