import styled from "styled-components";

export  const useStyles = (theme) => ({
    root: {

    },
    buttonSuccess: {
        backgroundColor: '#81b346',
        color: 'white',
        lineHeight: 40
    },
    buttonTrash: {
        backgroundColor: '#a73e3e',
        color: 'white',
        lineHeight: 40
    },
    profileImage: {
        border: "1px solid #00978c",
        backgroundColor: "#fff",
        color: "#00978C",
        width: "100%",
        height: "100%",
        fontSize: 24,
        borderRadius: 5
    },
    profile: {
        backgroundColor: '#fafbfb',
        color: '#828d99',
        padding: 15,
        marginLeft: 5,
        lineHeight: '2em',
        fontSize: 16,
        '& h3': {
            fontSize: 18,
            color: '#304156'
        },
        '& span': {
            color: '#304156'
        },
        '& p': {
            fontSize: 16,
        },
        '& a': {
            color: '#3a7ecc'
        },
        '& a:hover': {
            textDecoration: 'underline'
        }
    },
    form: {
        margin: 'auto',
        minWidth: '25%',
        '& .MuiGrid-item':{
             paddingRight: 10
        }
    },
    formControl: {
        display: 'block',
        '& input' :{

        },
        marginBottom: 10
    },
    textField: {
        width: '100%'
    }
});
