import React, {Component} from 'react';
import { DropzoneArea } from "material-ui-dropzone";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import ImageIcon from "@material-ui/icons/Image";
import {config} from '../../config';
import {Trans} from "react-i18next";

class AddPhotoDropzoneArea extends Component {
	constructor(props) {
		super(props);
		//console.log(this.props.avatar);
		this.state = {
			files: [],
			isDropzoneDisplayed: null
		};
		if(this.state.isDropzoneDisplayed)
			this.state.files.push(this.props.avatar.link.replace('/var/www', config.BACKEND));
		//console.log(this.state.isDropzoneDisplayed);
	}

	displayDropzone = () => {
		this.setState({
			isDropzoneDisplayed: true
		})
	};

	resetDropzone = () => {
        console.log("changeImage..");
        //this.displayDropzone();
        this.hideDropzone();
		this.props.updateValue([])
	};

	hideDropzone = () => {
		this.setState({
			isDropzoneDisplayed: false
		})
	};

	render() {
		let renderedElement;
		let isDropzoneDisplayed = false;
		isDropzoneDisplayed = this.props.avatar;
		if(this.state.isDropzoneDisplayed != null){
			isDropzoneDisplayed = this.state.isDropzoneDisplayed;
		}

		if (isDropzoneDisplayed) {
			if (this.props.avatar === null) {
				renderedElement = (<div className="wrapperDropzoneArea">
					<Tooltip title={<Trans i18nKey={"DropZoneImageTitle"}>{"DropZoneImageTitle"}</Trans>}>
							<DropzoneArea
								style={{height: "100%"}}
								showPreviewsInDropzone={false}
								filesLimit={1}
								dropzoneText={<Trans i18nKey={"DropZoneImageInfo"}>{"DropZoneImageInfo"}</Trans>}
                                acceptedFiles={this.props.acceptedFiles}
								onChange={this.props.updateValue}
								dropzoneClass={"dropzonePhotoUserNew"}
								dropzoneParagraphClass={"dropzoneParagraph"}
							/>
					</Tooltip>
					</div>
				)
			} else {
				renderedElement = (
					<div className="wrapperDropzoneArea">
						<Tooltip title={<Trans i18nKey={"DropZoneImageTitleRemove"}>{"DropZoneImageTitleRemove"}</Trans>}
								 className={"changeImage"}
                                 onClick={this.resetDropzone}
                        >
							<IconButton aria-label="delete"> <ImageIcon /> </IconButton>
						</Tooltip>
						<img
							className={"addAvatar"}
							style={{objectFit: "cover", width: '100%'}}
							src={ this.props.avatar.link.replace('/var/www/files', config.BACKEND) }
							alt="Автарка клиента"
						/>
					</div>
				)
			}
		} else {
			renderedElement = <div className="wrapperDropzoneArea">
				<Tooltip title={<Trans i18nKey={"DropZoneImageTitle"}>{"DropZoneImageTitle"}</Trans>}>
					<DropzoneArea
						style={{height: "100%"}}
						showPreviewsInDropzone={false}
						filesLimit={1}
						dropzoneText={<Trans i18nKey={"DropZoneImageInfo"}>{"DropZoneImageInfo"}</Trans>}
						acceptedFiles={this.props.acceptedFiles}
						onChange={this.props.updateValue}
						dropzoneClass={"dropzonePhotoUserNew"}
						dropzoneParagraphClass={"dropzoneParagraph"}
					/>
				</Tooltip>
			</div>
		}
		return (renderedElement);
	}
}

export default AddPhotoDropzoneArea;
