import React,{Component} from "react";
import Box from '@material-ui/core/Box';

export default class Ru extends Component{


    render() {
        const {classes} = this.props;
        return <>
            <h1>ПОЛИТИКА КОНФИДЕНЦИАЛЬНОСТИ</h1>
            <Box component="p" m={3}>ПОЖАЛУЙСТА, ВНИМАТЕЛЬНО ПРОЧИТАЙТЕ СЛЕДУЮЩУЮ ПОЛИТИКУ КОНФИДЕНЦИАЛЬНОСТИ ПЕРЕД ИСПОЛЬЗОВАНИЕМ ПРЕДОСТАВЛЕННОГО НАМИ ПРИЛОЖЕНИЯ. ПРИНИМАЯ ИЛИ ИСПОЛЬЗУЯ НАШЕ ПРИЛОЖЕНИЕ
                (ЗА ИСКЛЮЧЕНИЕМ ПЕРВОГО ПРОЧИТАНИЯ ДАННОЙ ПОЛИТИКИ КОНФИДЕНЦИАЛЬНОСТИ), ВЫ СОГЛАШАЕТЕСЬ С УСЛОВИЯМИ ДАННОЙ ПОЛИТИКИ КОНФИДЕНЦИАЛЬНОСТИ.
            </Box>
            <Box component="p" m={3}>Персональные данные, предоставленные вами с помощью любого из средств, предусмотренных для этой цели через наше Приложение или веб-сайт Speaker-ID.com,
                будут обрабатываться службой Speaker-ID в соответствии со следующей политикой конфиденциальности.
            </Box>
            <h2>Сбор информации о пользователях</h2>
            <Box component="p" m={3}>Мы собираем личную информацию от пользователей для ведения нашего бизнеса и предоставления вам наших решений.
                Вам необходимо зарегистрироваться, чтобы использовать наше приложение  Speaker-ID сервиса. Мы не собираем точную информацию о местонахождении вашего устройства.
                Наше приложение может автоматически собирать определенную неличную информацию, включая, помимо прочего, тип используемого вами мобильного устройства, тип мобильной операционной системы,
                поставщика сетевых услуг, IP-адрес вашего мобильного устройства и информацию о том, как вы используете приложение.
            </Box>
            <h2>Использование собранной информации о пользователях</h2>
            <Box component="p" m={3}>Мы используем собранную информацию исключительно с целью отслеживания ошибок, улучшения нашего Приложения или функциональности службы Speaker-ID или улучшения взаимодействия с пользователем.
            </Box>
            <h2>Обмен информацией о пользователях</h2>
            <Box component="p" m={3}>Мы можем использовать Google Firebase Analytics для сбора неличной информации в виде различных показателей использования и пользовательских показателей при использовании вами Приложения.
                В противном случае мы не продаем, не сдаем в аренду, не передаем и не раскрываем информацию о пользователях другим третьим лицам, за исключением некоторых ограниченных случаев:
                мы обязаны делать это по закону или если это разумно необходимо для соблюдения судебного процесса, реагировать на любых претензий или для защиты прав, собственности или личной безопасности Приложения,
                наших клиентов или общественности. Информация о наших клиентах может быть раскрыта в рамках или во время переговоров по любому слиянию, продаже активов компании или приобретению.
            </Box>
            <h2>Отказ от использования</h2>
            <Box component="p" m={3}>Вы можете легко прекратить использование всей информации, собираемой службой Speaker-ID, удалив наше Приложение службы Speaker-ID. Вы можете использовать стандартные процессы удаления,
                которые могут быть доступны как часть вашего мобильного устройства или через запрос в сервис Speaker-ID.
            </Box>
            <h2>Дети</h2>
            <Box component="p" m={3}>Сервис Speaker-ID может использоваться детьми, но он не предназначена для использования детьми без участия, надзора и одобрения родителей или законного опекуна.
                Сервис Speaker-ID сознательно не собирает информацию от детей младше 13 лет без согласия родителей. Если вам станет известно, что лицо младше 13 лет воспользовалось услугой Speaker-ID без разрешения родителей, немедленно сообщите нам об этом.
            </Box>
            <h2>Безопасность</h2>
            <Box component="p" m={3}>Вся собранная информация хранится на наших собственных серверах баз данных с ограниченным доступом или на сервере Google Firebase Analytics.
                Мы ограничиваем доступ к этой информации для уполномоченных сотрудников, которым необходимо знать эту информацию для работы, разработки или улучшения нашего Приложения.
                Имейте в виду, что, хотя мы стремимся обеспечить разумную безопасность информации, которую мы обрабатываем и поддерживаем, никакая система безопасности не может предотвратить все потенциальные нарушения безопасности.
            </Box>
            <h2>Ссылки на другие сайты</h2>
            <Box component="p" m={3}>Наше Приложение сервиса Speaker-ID может содержать ссылки на другие сайты. Если вы нажмете на стороннюю ссылку, вы будете перенаправлены на этот сайт. Обратите внимание, что эти внешние сайты не управляются нами.
                Поэтому мы настоятельно рекомендуем вам ознакомиться с Политикой конфиденциальности этих веб-сайтов. Мы не контролируем и не несем ответственности за контент, политику конфиденциальности или действия любых сторонних сайтов или служб.
                Наше Приложение может использовать ссылку на политику конфиденциальности сторонних поставщиков услуг: Google Play Services, Google Firebase Analytics.
            </Box>
            <h2>Локализация Персональной информации</h2>
            <Box component="p" m={3}>Сервис Speaker-ID находится на территории Российской Федерации. Ваша личная информация будет храниться в Российской Федерации.
                Всякий раз, когда мы передаем личную информацию по запросу в другие юрисдикции, мы гарантируем, что информация передается в соответствии с настоящим Уведомлением о конфиденциальности и в соответствии с применимыми законами о защите данных.
            </Box>
        </>;
    }
}

