import React, { Component } from "react";
import {BrowserRouter as Router } from "react-router-dom";
import history from "./services/history";
import Routes from "./routes";
import GlobalStyles from "./styles/global";
import { UserContext, user } from "./context/UserContext";
import {NotificationContainer, NotificationManager} from 'react-notifications';
import GlobalFonts from './styles/fonts'
import 'react-notifications/lib/notifications.css';
import '../src/services/i18n';
import './App.css';
import "swiper/swiper-bundle.css";
import { instanceOf } from "prop-types";
import PopupCookies from "./components/PopupCookies"
import { withCookies, Cookies } from "react-cookie";
import {Trans} from "react-i18next";
import userStore from "./store/UserStore";

const PUBLIC_URL = '/';

class App extends Component {
    timer = null;
    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
    };

    handleCookie = () => {
        const { cookies } = this.props;
        console.log(this);
        console.log('handleCookie, cookies: ', cookies    );
        cookies.set("userCookieConfirm", "true", { path: "/" }); // setting the cookie
        this.setState({isShowCookies: false});
    };

    constructor(props) {
        super(props);
        this.changeUser = () => {
            user.firstname = '';
            return user;
        };

        this.setAuthUser = () => {

            this.setState(state => ({
                user: user
            }));
        };

        this.state = {
            user: user,
            setAuthUser: this.setAuthUser,
            signIn: (login, password) => {
                this.state.user.signIn(login, password).then(data => {
                    this.state.user.getInfo().then(data => {
                        // console.log("signIn, getInfo, data", data);
                        if(data.statusCode > 300) {
                            NotificationManager.error(data.message, <Trans i18nKey={"SentMessageError"}>{"SentMessageError"}</Trans>);
                        } else {
                            this.state.user.setUserInfo(data);
                            // data.logn, data.email,..., data.maanger
                            this.state.user.saveUserInfo(data);
                            this.setState({user: this.state.user});
                        }
                    });
                });
            },
            signOut: () => {
                this.state.user.signOut().then(data => {
                    this.setState({user: this.state.user});//this.state.user
                    clearTimeout(this.timer);
                });
            },
            isUserSignedIn: (login, password) => {
                return this.state.user.signIn(login, password)
            },
            setLang: (event) => {
                let user = this.state;
                this.setState({user: this.state.user.setLang(event.target.value.split('-').shift())})
            },
            isShowCookies: this.props?.cookies ? this.props.cookies.get("userCookieConfirm") !== "true" : true
        };
    }

    render() {
    const basename = process.env.NODE_ENV === 'development' ? '/' : (PUBLIC_URL || '/');
      return (
          <UserContext.Provider value={this.state} >
              <GlobalFonts />
              <Router history={history}  basename={basename} >
                  <Routes isSignedIn={this.state.user.isSignIn()}/>
                  <GlobalStyles />
              </Router>
              <NotificationContainer/>
              {this.state.isShowCookies ?
                  (
                          <PopupCookies handlerCookie={this.handleCookie}/>
                  )
                  : ""
              }
          </UserContext.Provider>
      );
  }
}
export default withCookies(App);
