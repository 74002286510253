import {Trans} from "react-i18next";
import React from "react";

export const i18nresources = {
    en: {
        translations: {
            Back: "Back",
            SignUp: "Sign Up",
            SignIn: "Sign In",
            Users: "Users",
            VoicePrints: "Voice prints",
            VoicePrintsList: "Voice prints",
            VoiceAuthentication: "Voice authentications",
            VoiceAuthenticationList: "Voice authentications",
            Analytics: "Analytics",
            Add: "Add",
            Delete: "Delete",
            Block: "Block",
            Activate: "Activate",
            ReadMode: "ReadMode",
            ErrorWithComparePassword: "Password doesn't match",
            Colleagues: "Colleagues",
            ColleagueAdd: "Add colleague",
            ColleaguesDeleting: "Deleting colleagues",
            ColleaguesBlocking: "Blocking colleagues",
            ColleaguesActivate: "Activation colleagues",
            ColleaguesReadMode: "Read mode for colleagues",
            ColleaguesDeletingText: "Delete the selected colleagues ?",
            ColleaguesBlockingText: "Block the selected colleagues ?",
            ColleaguesActivateText: "Activate the selected colleagues ?",
            ColleaguesReadModeText: "Set the read mode for the selected colleagues ?",
            ColleagueUpdated: "Edit colleague data",
            Copyright: "Copyright © GritTec Ltd. All rights reserved.",
            LoginIntoYourAccount: "Login into your account",
            LinkRegistr: "Don't have an account? Register now",
            Password: "Password",
            RepeatPassword: "Repeat Password",
            Privacypolicy: "Privacy policy",
            CookiesPolicy: "Cookies Policy",
            CookiesPolicyInfo: "We use cookies to enhance your experience while on our website. By continuing to browse our website you are agreeing to our use of cookies.\n More details find in section ",
            Useragreement: "User agreement",
            Profile: "Profile",
            ProfileOwner: "Owner profile",
            Settings: "Settings",
            Feedback: "Feed back",
            Exit: "Exit",
            UserList: "User List",
            Search: "Search",
            Clear: "Clear",
            Active: "Active ",
            Disactive: "Disactive ",
            Blocked: "Blocked",
            FirstName: "First Name",
            MiddelName: "Middel Name",
            LastName: "Last Name",
            FIO: "FIO",
            Status: "Status",
            NumberRecords: "Records",
            OnPage: "on page",
            FileName: "File Name",
            Date: "Date",
            Duration: "Duration",
            Comment: "Comment",
            Error: "Error",
            Accepted: "Accepted",
            Rejected: "Rejected",
            FA:"FA, %",
            FR:"FR, %",
            FAR_threshold:"thr. FAR, %",
            FRR_threshold:"thr. FRR, %",
            EditingThresholds: "Edit error thresholds",
            SNR_DB: "SNR, dB",
            EditingClient: "Edit",
            Send: "Send",
            Save: "Save",
            SentMessage: "Message sent ",
            SentMessageError: "Message not sent",
            SentMessageSuccessed: "Changes saved successfully!",
            Cancel: "Cancel",
            ChangesSaved: "Changes saved !",
            ErrorAddAvatar: "Error of uploading avatar",
            ErrorMessageRequestField: "Fill out the required field",
            Message: "Message",
            Gender: "Gender",
            M: "Men",
            W: "Woman",
            DateBirth: "Date of Birth",
            DateBirth2: "Date of Birth (YYYY-MM-DD)",
            DateFrom: "From",
            DateTo: "To",
            DateSelect: "Select period",
            DateCreated: "Date created",
            DateUpdated: "Date updated",
            Created: "Created",
            Updated: "Updated",
            Nationality: "Nationality",
            Avatar: "Avatar",
            Phone: "Phone",
            PlaceBirth: "Place of Birth",
            PlaceLiving: "Place of living",
            AdditionalInformation: "Additional",
            UserVoicePrint: "Voice prints",
            UserVoiceAuthentication: "Voice authentication",
            DragAndDropAFileHereOrClick: "Drag and Drop a file here or click",
            DropZoneImageInfo: "You can do  wnload image upto 250x300px in jpg or png formats.",
            DropZoneImageTitle: "Draanddrop image and click!",
            DropZoneImageTitleRemove: "Delete selected image",
            DateAndTime: "Date And Time",
            DurationSecond: "Duration (second)",
            PassportNo: "Passport No",
            CitizenShip: "Citizen Ship",
            PassportInfo: "Passport info...",
            DriverNo: "Driver No",
            DriverLicense: "DriverLicense",
            PushToken: "Push Token",
            Info: "Additional information",
            Gender_Woman: "Gender: Woman",
            Woman: "Woman",
            Men: "Men",
            Insurance: "Insurance",
            Height: "Height",
            Weight: "Weight",
            Passport: "Passport",
            Documents: "Documents",
            DrivenNo: "Driven No",
            Contacts: "Contacts",
            Other: "Other",
            Close: "Close",
            DoesNotMatter: "Does not matter",
            Filter: "Filter",
            User: "User",
            EnrollnAlytics: "Total quantity of voice prints:",
            EnrollnAlyticsForTime: "Quantity of voice prints for:",
            VerifynAlytics: "Total quantity of voice authentications:",
            VerifynAlyticsForTime: "Quantity of voice authentications for:",
            CommonStat: "Total",
            total_activeStat: "Active",
            total_notactiveStat: "No Active",
            NumberUsers: "Total quantity of users:",
            NumberUsersForTime: "Quantity of users for:",
            quantity_successStat: "Successful",
            quantity_acceptStat: "Accepted",
            quantity_rejectStat: "Rejected",
            quantity_errorStat: "Errors",
            _24hour: "24 hour",
            _3day: "3 days",
            _1week: "1 week",
            _1month: "1 month",
            _3month: "3 months",
            _6month: "6 months",
            _1year: "1 year",
            Year: "Year",
            Registration: "Registration",
            RegistrationError: "Error of registration",
            RegistrationErrorLogin: "Login must be at least 4 characters long",
            RegistrationErrorEmail: "Email must be an email format",
            RegistrationErrorPhone: "Phone must be a phone number format",
            RegistrationErrorFirstname: "Firstname must be at least 2 characters long",
            RegistrationErrorLastname: "Lastname must be at least 3 characters long",
            RegistrationErrorOrganization: "Organization must be at least 2 characters long",
            RegistrationErrorFieldactivity: "Field activity must be at least 2 characters long",
            RegistrationErrorWorkposition: "Workposition must be at least 2 characters long",
            RegistrationErrorPasswordEmpty: "Password is empty",
            RegistrationErrorPasswordNotMatch: "Passwords do not match",
            RegistrationSuccessful: "Registration successful !",
            Activation: "Activation",
            Confirmation: "Confirmation",
            Name: "Name",
            Login: "Login",
            Country: "Country",
            WorkEmail: "Work Email",
            Organization: "Organization",
            FieldOfActivity: "Field of activity",
            Position: "Position",
            ConfirmPersonalData: "I consent to the storage of the specified personal data in order to process my request",
            IfExistAccauntStart: "Already have an account?",
            IfExistAccauntFinish: "to the office",
            PincodeEmail: "Enter the PIN code sent to your Email",
            PinCode: "Pin Code",
            Confirm: "Confirm",
            AccountActivate: "Waiting for your account to be approved, it will take a few days",
            PincodeError: "Pincode error",
            QntAuthentication: "Qnt. auth.",
            QntEnroll: "Qnt. prints",
            All: "All",
            QntNo: "No",
            QntYes: "Yes",
            NoMatter: "No Matter",
            AudioFileLoaded: "Audio file has been loaded!",
            SelectedFilesDeleted: "Selected files has been deleted!",
            SelectedItemssDeleted: "Selected items has been deleted!",
            SelectedItemssBlocked: "Selected items has been blocked!",
            SelectedItemssActivated: "Selected items has been activated!",
            AddedNewItem: "A new item has been added!",
            EditedItem: "Item has been updated !",
            Deleted: "Deleted",
            Disabled: "Disabled",
            Enabled: "Enabled",
            Undefined: "Undefined",
            tEdit: "Edit",
            Waiting: "Waiting",
            selectActive: "Enabled",
            selectDisable: "Disabled",
            selectReadMode: "Read mode",
        }
    },
    ru: {
        translations: {
            SignIn: "Войти",
            SignUp: "Зарегистрироваться",
            Password: "Пароль",
            RepeatPassword: "Повторите пароль",
            Users: "Пользователи",
            VoicePrints: "Голосовые отпечатки",
            VoicePrintsList: "Голосовые отпечатки",
            VoiceAuthentication: "Голосовые аутентификации",
            VoiceAuthenticationList: "Голосовые аутентификации",
            Analytics: "Аналитика",
            Add: "Добавить",
            Delete: "Удалить",
            Block: "Блокировать",
            Activate: "Активировать",
            ReadMode: "Чтение",
            ErrorWithComparePassword: "Пароль не совпадает",
            Colleagues: "Коллеги",
            ColleagueAdd: "Добавить коллегу",
            ColleaguesDeleting: "Удаление коллег",
            ColleaguesBlocking: "Блокировка коллег",
            ColleaguesActivate: "Активация коллег",
            ColleaguesReadMode: "Режим чтения для коллег",
            ColleaguesDeletingText: "Удалить выделенных коллег ?",
            ColleaguesBlockingText: "Блокировать выделенных коллег ?",
            ColleaguesActivateText: "Активировать выделенных коллег ?",
            ColleaguesReadModeText: "Установить режим чтения для выделенных коллег ?",
            ColleagueUpdated: "Обновить данные коллеги",
            Copyright: "Copyright © ООО \"ГритТек\". Все права защищены.",
            LoginIntoYourAccount: "Войдите в свою учетную запись",
            LinkRegistr: "Нет аккаунта? Зарегистрируйтесь",
            Privacypolicy: "Политика конфиденциальности",
            CookiesPolicy: "Политика cookie",
            CookiesPolicyInfo: "Мы используем файлы cookie, чтобы улучшить ваш опыт на нашем веб-сайте. Продолжая просматривать наш веб-сайт, вы соглашаетесь на использование нами файлов cookie. Более подробную информацию можно найти в разделе ",
            Useragreement: "Пользовательское Соглашение",
            Profile: "Профиль",
            ProfileOwner: "Профиль владельца",
            Settings: "Настройки",
            Feedback: "Обратная связь",
            Exit: "Выход",
            UserList: "Список пользователей",
            Search: "Поиск",
            Clear: "Очистить",
            Active: "Активен ",
            Disactive: "Неактивен ",
            Blocked: "Блокирован",
            FirstName: "Имя",
            MiddelName: "Отчество",
            LastName: "Фамилия",
            FIO: "ФИО",
            Status: "Статус",
            NumberRecords: "Записей",
            OnPage: "на странице",
            FileName: "Имя файла",
            Date: "Дата",
            Duration: "Длительность",
            Comment: "Комментарий",
            Error: "Ошибка",
            Accepted: "Принято",
            Rejected: "Отказано",
            FA:"FA, %",
            FR:"FR, %",
            FAR_threshold:"Порог FAR, %",
            FRR_threshold:"Порог FRR, %",
            EditingThresholds: "Редактировать пороги ошибок",
            SNR_DB: "SNR, dB",
            EditingClient: "Редактировать",
            Send: "Отправить",
            Save: "Сохранить",
            SentMessage: "Сообщение отправлено !",
            SentMessageError: "Сообщение не отправлено",
            SentMessageSuccessed: "Изменения сохранены успешно!",
            Cancel: "Отменить",
            ChangesSaved: "Изменения сохранены",
            ErrorAddAvatar: "Ошибка добавления Аватара",
            ErrorMessageRequestField: "Заполните обязательное поле",
            Message: "Сообщение",
            Gender: "Пол",
            M: "Муж",
            W: "Жен",
            DateBirth: "Дата рождения",
            DateBirth2: "Дата рождения (ГГГГ-ММ-ДД)",
            DateFrom: "С",
            DateTo: "По",
            DateSelect: "Выберете период",
            DateCreated: "Дата создания",
            DateUpdated: "Дата обновления",
            Created: "Создано",
            Updated: "Обновлено",
            Nationality: "Национальность",
            Avatar: "Аватар",
            Phone: "Телефон",
            PlaceBirth: "Место рождения",
            PlaceLiving: "Место проживание",
            AdditionalInformation: "Дополнительно",
            UserVoicePrint: "Голосовые отпечатки",
            UserVoiceAuthentication: "Голосовые аутентификации",
            DragAndDropAFileHereOrClick: "Перетащите сюда файл или Щелкните",
            DropZoneImageInfo: "Вы можете загрузить изображение до 250x300px в формате jpg или png.",
            DropZoneImageTitle: "Перетащите изображение или кликните!",
            DropZoneImageTitleRemove: "Удалить выбранное изображение",
            DateAndTime: "Дата и Время",
            DurationSecond: "Продолжительность (секунды)",
            PassportNo: "Паспорт №",
            CitizenShip: "Гражданство",
            PassportInfo: "Паспорт инфо...",
            DriverNo: "Права №",
            DriverLicense: "Водительские права",
            PushToken: "Push Token",
            Info: "Дополнительная информация",
            Gender_Woman: "Пол: Женский",
            Woman: "Женский",
            Men: "Мужской",
            Insurance: "Страховка",
            Height: "Рост",
            Weight: "Вес",
            Passport: "Паспорт",
            Documents: "Документы",
            DrivenNo: "Права №",
            Contacts: "Контакты",
            Other: "Разное",
            Close: "Отмена",
            DoesNotMatter: "Не важно",
            User: "Пользователь",
            EnrollnAlytics: "Общее количество голосовых отпечатков:",
            EnrollnAlyticsForTime: "Количество голосовых отпечатков за:",
            VerifynAlytics: "Общее количество голосовых аутентификаций:",
            VerifynAlyticsForTime: "Количество голосовых аутентификаций за:",
            Filter: "Фильтрация",
            CommonStat: "Общее",
            total_activeStat: "Активных",
            total_notactiveStat: "Неактивных",
            NumberUsers: "Общее количество пользователей:",
            NumberUsersForTime: "Количество пользователей за:",
            quantity_successStat: "Успешных",
            quantity_acceptStat: "Принятых",
            quantity_rejectStat: "Отказов",
            quantity_errorStat: "Ошибок",
            _24hour: "24 часа",
            _3day: "3 дня",
            _1week: "1 неделю",
            _1month: "1 месяц",
            _3month: "3 месяца",
            _6month: "6 месяцев",
            _1year: "1 год",
            Year: "Год",
            Registration: "Регистрация",
            RegistrationError: "Ошибка при регистрации",
            RegistrationErrorLogin: "Длина логина должна иметь не менее 4-х символов",
            RegistrationErrorEmail: "Email должен быть в формате электронной почты",
            RegistrationErrorPhone: "Телефон должен быть в формате номера телефона",
            RegistrationErrorFirstname: "Длина 'Имени' должна иметь не менее 2-х символов",
            RegistrationErrorLastname: "Длина 'Фамилии' должна иметь не менее 3-х символов",
            RegistrationErrorOrganization: "Длина 'Место работы' должна иметь не менее 2-х символов",
            RegistrationErrorFieldactivity: "Длина 'Сферы деятельности' должна иметь не менее 2-х символов",
            RegistrationErrorWorkposition: "Длина 'Должности' должна иметь не менее 2-х символов",
            RegistrationErrorPasswordEmpty: "Пароль пустой",
            RegistrationErrorPasswordNotMatch: "Пароли не совпадают",
            RegistrationSuccessful: "Регистрации успешна!",
            Activation: "Активация",
            Confirmation: "Подтверждение",
            Name: "Имя",
            Login: "Логин",
            Country: "Страна",
            WorkEmail: "Рабочий Email",
            Organization: "Место работы",
            FieldOfActivity: "Сфера деятельности",
            Position: "Должность",
            ConfirmPersonalData: "Я даю согласие на хранение указанных персональных данных в целях обработки моего запроса",
            IfExistAccauntStart: "Уже есть аккаунт?",
            IfExistAccauntFinish: "в кабинет",
            PincodeEmail: "Введите ПИН-код отправленный на ваш Email",
            PinCode: "ПИН код",
            Confirm: "Подтвердить",
            AccountActivate: "Ожидайте одобрения вашей учетной записи, это может занять несколько дней",
            PincodeError: "Введен не правильный ПИН код",
            Back: "Назад",
            QntAuthentication: "Кол. аутенф.",
            QntEnroll: "Кол. отпечат.",
            All: "Все",
            QntNo: "Нет",
            QntYes: "Есть",
            NoMatter: "Не важно",
            AudioFileLoaded: "Аудио файл был загружен!",
            SelectedFilesDeleted: "Выделенные файлы удвлены!",
            SelectedItemssDeleted: "Выделенные записи удвлены!",
            SelectedItemssBlocked: "Выделенные записи заблокированы!",
            SelectedItemssActivated: "Выделенные записи активированы!",
            AddedNewItem: "Добавлена новая запись!",
            EditedItem: "Запись обновлена!",
            Deleted: "Удален",
            Disabled: "Заблокирован",
            Enabled: "Активен",
            Undefined: "Не указан",
            tEdit: "Редактировать",
            Waiting: "Ожидания",
            selectActive: "Активен",
            selectDisable: "Заблокирован",
            selectReadMode: "Чтение",
        }
    }
}
