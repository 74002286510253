import React, {Component}  from "react";
import {createTheme, ThemeProvider, withStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import AccountCircle from '@material-ui/icons/AccountCircle';
import Popover from '@material-ui/core/Popover';
import {UserContext} from "../../context/UserContext";
import Card from "@material-ui/core/Card";
import { connect } from "react-redux"
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Divider from '@material-ui/core/Divider';
import {Avatar, Dialog} from "@material-ui/core";
import UserDialogForm from "../shared/UserDialogForm/UserDialogForm";
import userStore from "../../store/UserStore";
import {Link} from "react-router-dom";
import {config} from '../../../src/config';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import SettingsIcon from '@material-ui/icons/Settings';
import TelegramIcon from '@material-ui/icons/Telegram';
import GroupIcon from '@mui/icons-material/Group';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';

import {Trans} from "react-i18next";
import { loginUser } from '../../actions/userActions'
import {NotificationManager} from "react-notifications";
import DialogFeedBack from "../Dialog/DialogFeedBack";

const useStyles = (theme) => ({
    sectionDesktop: {
        display: 'block',
        float: 'right',
        marginRight: 10,
        marginTop: 0,
        padding: '2px 0'
    },
    typography: {
        padding: theme.spacing(2),
    },
    profileIcon: {
        border: "1px solid #00978c",
        backgroundColor: "white",
        color: "#00978C",
        width: 24,
        height: 24,
        fontSize: 24,
        borderRadius: 12
    },
    root: {

    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
});

const theme = createTheme({
    palette: {
        primary: {
            main: "#00978C",
        },
    },
});


class UserPanelShort extends Component {
    open = false;
    menuId = 'primary-search-account-menu';
    state = {
        anchorEl: null,
        isLeftMenuOpen: false,
        anchorElProfileMenu: null,
        isUserProfileOpen: false,
        isEditUserDialogOpen: false,
        userInfo: {
            login: "",
            firstname: "",
            lastname: "",
            middlename: "",
            avatar: "",
        },
        isOpenDialogFeedBack: false,
    };

    handleOpenEditUserDialog = () => {
        this.setState({
            isEditUserDialogOpen: true,
        });
        this.handleProfileMenuClose()
    };

    handleCloseEditUserDialog = () => {
        this.setState({
            isEditUserDialogOpen: false,
        });
        userStore.hideUserSettings();
    };

    handleProfileMenuOpen = (event) => {
        this.setState({isUserProfileOpen: true, anchorElProfileMenu: event.currentTarget});
    };

    handleProfileMenuClose = (event) => {
        this.setState({isUserProfileOpen: false, anchorElProfileMenu: null});
    };

    componentDidMount() {
        this.updateUserData()
    }

    updateUserData = () => {
        userStore.getInfo()
            .then(value => {
                this.setState({
                    userInfo: {
                        login: value.login,
                        firstname: value.manager.firstname,
                        lastname: value.manager.lastname,
                        middlename: value.manager.middlename,
                        avatar: value.avatarUser,
                    }
                }, () => '')
            })
    }

    handleClick = (event) => {
        this.setState({anchorEl: event.currentTarget}, () => {
            this.open = Boolean(this.state.anchorEl);
        });
    };

    handleClose = () => {
        this.setState({anchorEl: null}, () => {
            this.open = Boolean(this.state.anchorEl);
        });
    };

    handleFeedbackDialogOpen  = () => {
        this.handleProfileMenuClose();
        this.setState({
            isOpenDialogFeedBack: true,
        });
    };

    render() {
        const { classes } = this.props;
        // console.log('render user panel');
        return (
            <UserContext.Consumer>
                {({user, setAuthUser, signOut, showSettings}) => (
                    <div className={classes.sectionDesktop + ' User-profile'}>
                            <DialogFeedBack
                                isOpen={this.state.isOpenDialogFeedBack}
                                handleClose={event=>this.setState({isOpenDialogFeedBack: false})}
                                handleSave={()=> {
                                    this.setState({
                                        isOpenDialogFeedBack: false,
                                    });
                                }
                                }
                            />
                        <IconButton
                            style={{borderRadius: "16px"}}
                            edge="end"
                            aria-label="account of current user"
                            aria-controls={this.menuId}
                            aria-haspopup="true"
                            onClick={this.handleProfileMenuOpen}
                            color="inherit"
                            aria-describedby={"user-profile-popover"}
                        >
                            <div className={"userName"} style={{textAlign: "right", fontWeight: "500", fontSize: "0.7em", marginRight: "0.5em"}}>
                                <small style={{ opacity: "0.8", fontSize: "0.9em", fontWeight: "normal", color: "#00978C" }}>{user.type}</small>
                                <span style={{ clear: "both", display: "block", color: "#313436", textTransform: 'uppercase'}}>{this.state.userInfo.lastname}</span>
                            </div>
                            <Avatar
                                className={classes.profileIcon}
                                src={this.state.userInfo.avatar === "" || this.state.userInfo.avatar === null
                                    ? ""
                                    : config.BACKEND + this.state.userInfo.avatar.link.replace("/var/www/files", "")
                                }
                            />

                            {this.state.isUserProfileOpen
                                ? <ExpandLessIcon color={"action"}/>
                                : <ExpandMoreIcon color={"action"}/>
                            }
                        </IconButton>

                        <Popover
                            id={"user-profile-popover"}
                            anchorEl={this.state.anchorElProfileMenu}
                            open={this.state.isUserProfileOpen}
                            onClose={this.handleProfileMenuClose}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right'
                            }}
                            arrow
                        >

                            <Card className={classes.root}>
                                <Link to="/profile">
                                    <MenuItem onClick={this.handleProfileMenuClose} >
                                        <Typography variant="inherit" style={{display: 'block', width: 140}}>
                                            <Trans i18nKey={"Profile"} >{"Profile"}</Trans>
                                        </Typography>
                                        <PersonOutlineIcon fontSize="small" />
                                    </MenuItem>
                                </Link>
                                {/*
                                    <Link to="/settings">
                                        <MenuItem onClick={this.handleProfileMenuClose} >
                                            <Typography variant="inherit" style={{display: 'block', width: 140}}><Trans
                                                i18nKey={"Settings"}>{"Settings"}</Trans></Typography>
                                            <SettingsIcon fontSize="small"/>

                                        </MenuItem>
                                    </Link>
                                */}
                                {!userStore.isParentManager() ? (
                                <Link to="/colleagues">
                                    <MenuItem onClick={this.handleProfileMenuClose} >
                                        <Typography variant="inherit" style={{display: 'block', width: 140}}>
                                            <Trans i18nKey={"Colleagues"} >{"Colleagues"}</Trans>
                                        </Typography>
                                        <GroupIcon fontSize="small" />
                                    </MenuItem>
                                </Link> )
                                    : ("")
                                }

                                <MenuItem onClick={this.handleFeedbackDialogOpen} >
                                    <Typography variant="inherit" style={{display: 'block', width: 140}}>
                                        <Trans i18nKey={"Feedback"} >{"Feedback"}</Trans>
                                    </Typography>
                                    <TelegramIcon fontSize="small" />
                                </MenuItem>

                                <MenuItem onClick={signOut} style={{backgroundColor: '#fafbfb'}}>
                                    <Typography variant="inherit" style={{display: 'block', width: 140, fontWeight: 'bold'}}>
                                        <Trans i18nKey={"Exit"} >{"Exit"}</Trans>
                                    </Typography>
                                </MenuItem>
                            </Card>
                        </Popover>
                        <ThemeProvider theme={theme}>
                            <Dialog
                                open={this.state.isEditUserDialogOpen || user._showSettings}
                                fullWidth
                                maxWidth={"lg"}
                                aria-labelledby="form-dialog-title"
                            >
                                <UserDialogForm
                                    initialFormData={this.state.userInfo}
                                    closeDialog={this.handleCloseEditUserDialog}
                                    updateUserData={this.updateUserData}
                                />
                            </Dialog>
                        </ThemeProvider>
                    </div>
                )}
            </UserContext.Consumer>
        );
    }
}


const mapStateToProps = state => {
    return state;
}
const mapDispatchToProps = {
    loginUser
}

 export default withStyles(useStyles)(connect(mapStateToProps, mapDispatchToProps)(UserPanelShort))
// export default withStyles(useStyles)(UserPanelShort)


