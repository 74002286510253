import React,{Component} from "react";
import Box from '@material-ui/core/Box';
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import bitmap from "../../../static/img/bitmap.png";
import smartphone from "../../../static/img/smartphone_ru.png";
import googleplay from "../../../static/img/google_play_ru.png";
import appstore from "../../../static/img/app_store_ru.png";
import rustore from "../../../static/img/rustore_ru.png";
import rectangle from "../../../static/img/rectangle.png";
import banner_analytic from "../../../static/img/analytics_ru.png";
import banner_phones from "../../../static/img/phones.png"
import banner_account from "../../../static/img/account.png"
import banner_userlist from "../../../static/img/userlist_ru.png"
import banner_voiceprint from "../../../static/img/voicepront_ru.png"
import banner_voiceverify from "../../../static/img/voiceverify_ru.png";
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y  } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.min.css';
import {withStyles} from "@material-ui/core";
import {useStyles} from "../styles";
import {Link} from "react-router-dom";
import {Trans} from "react-i18next";

SwiperCore.use([Navigation]);

class Rus extends Component{


    render() {
        const {classes} = this.props;

        return <div>

            <section className={classes.sectionstartfree}>
                <Container >
                    <Grid container spacing={5} >
                        <Grid item xs={12} md={5} lg={5} >
                            <h1>Голосовая аутентификация</h1>
                            <p>
                                > Забудьте пароли, просто говорите<br/>
                                > Не зависит от текста и языка<br/>
                                > Подтверждение личности человека по голосу<br/>
                                > Доступ к конфиденциальной информации по голосу<br/>
                            </p>
                            <br/><br/>
                            <Link to="/register" >
                                <Button  variant="contained" className={classes.startbutton} >
                                    <Trans i18nKey={"SignUp"} >{"SignUp"}</Trans>
                                </Button>
                            </Link>
                        </Grid>
                        <Grid item xs={12} md={7} lg={7} style={{textAlign:'right', position: 'relative'}} >
                            <div className={classes.circle4}></div>
                            <div className={classes.circle3}></div>
                            <div className={classes.circle2}></div>
                            <div className={classes.circle1}></div>
                            <img src={bitmap} alt="" />
                        </Grid>
                    </Grid>
                </Container>
            </section>
            <section className={classes.sectionslider1}>
                <Container >
                    <Grid container spacing={4} >
                        <Grid item xs={12} md={12} lg={12} >
                            <Swiper
                                className={classes.swiper}
                                spaceBetween={50}
                                slidesPerView={1}
                                navigation={true}
                            >
                                <SwiperSlide>
                                    <Container >
                                        <Grid container spacing={4} >
                                            <Grid item xs={"auto"} md={1} lg={1}></Grid>
                                            <Grid item xs={12} md={6} lg={6} style={{textAlign:'left', position: 'relative'}} >
                                                <img src={rectangle} alt="" />
                                            </Grid>
                                            <Grid item xs={12} md={5} lg={5} >
                                                <h1>Что такое Speaker-ID ?</h1>
                                                <p>
                                                    Speaker-ID это облачный сервис основанный на технологии голосовой биометрии, который позволяет сравнить две аудио записи
                                                    с голосом человека и определить соответствуют ли они одному и тому же человеку, чтобы однозначно его идентифицироать.
                                                </p>
                                            </Grid>
                                        </Grid>
                                    </Container>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <Container >
                                        <Grid container spacing={4} >
                                            <Grid item xs={"auto"} md={1} lg={1}></Grid>
                                            <Grid item xs={12} md={6} lg={6} style={{textAlign:'left', position: 'relative'}} >
                                                <img src={rectangle}  alt="" />
                                            </Grid>
                                            <Grid item xs={12} md={5} lg={5} >
                                                <h1>Как это работает ?</h1>
                                                <p>
                                                    Технология Speaker-ID основана на парном сравнение спектральных параметров аудио записи
                                                    неизвестного голоса с спектральными параметрами аудио записи целевого голоса.
                                                    Спектральные параметры вычисляются с учетом динамического определения уровня канальных искажений и внешних помех и шумов.
                                                </p>
                                            </Grid>
                                        </Grid>
                                    </Container>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <Container >
                                        <Grid container spacing={4} >
                                            <Grid item xs={"auto"} md={1} lg={1}></Grid>
                                            <Grid item xs={12} md={6} lg={6} style={{textAlign:'left', position: 'relative'}} >
                                                <img src={rectangle}  alt="" />
                                            </Grid>
                                            <Grid item xs={12} md={5} lg={5} >
                                                <h1>Достоинства</h1>
                                                <p>
                                                    > Не зависит от текста и языка<br/>
                                                    > Не менее 15 секунд длительность аудио записи с голосовым образцом для получения голосового отпечатка<br/>
                                                    > Не менее 7 секунд длительность аудио записи с голосовым образцом для его верификации<br/>
                                                    > Операции с низким уровнем отношения сигнала-шум (SNR)<br/>
                                                    > Быстрая адаптация к канальным искажениям и внешним шумам<br/>
                                                </p>
                                            </Grid>
                                        </Grid>
                                    </Container>
                                </SwiperSlide>
                                ...
                            </Swiper>
                        </Grid>
                    </Grid>
                </Container>
            </section>
            <section className={classes.sectionslider3}>
                <Container >
                    <h1>Мониторинг сервиса</h1>
                    <Grid container spacing={4} >
                        <Grid item xs={12} md={12} lg={12} >
                            <Swiper
                                className={classes.swiper}
                                spaceBetween={50}
                                slidesPerView={1}
                                navigation={true}
                            >
                                <SwiperSlide>
                                    <Container >
                                        <Grid container spacing={4} >
                                            <Grid item xs={"auto"} md={1} lg={1}></Grid>
                                            <Grid item xs={12} md={5} lg={5} style={{textAlign:'left', position: 'relative'}}  >
                                                <h2>Мониторинг пользователей</h2>
                                                <br/>
                                                <p>
                                                    > Мониторинг списка зарегистрированных пользователей.<br/>
                                                    > Просмотр и редактирование профилей пользователей.<br/>
                                                    > Блокировка и активация профилей пользователей.<br/>
                                                    > Индвидульная настройка параметров аутентификации для каждого пользователя (уровень порогов ошибок принятия FAR и отказа FRR).<br/>
                                                </p>
                                            </Grid>
                                            <Grid item xs={12} md={6} lg={6} >
                                                <img src={banner_userlist}  alt="" />
                                            </Grid>
                                        </Grid>
                                    </Container>
                                </SwiperSlide>
                                <SwiperSlide>
                                    {/*
                                    <Container >
                                        <Grid container spacing={4} >
                                            <Grid item xs={"auto"} md={1} lg={1}></Grid>
                                            <Grid item xs={12} md={5} lg={5}  style={{textAlign:'left', position: 'relative'}} >
                                                <img src={banner1}  alt="" />
                                                <h1>Мониторинг регистрации голосовых отпечатков</h1>
                                                <p>
                                                    Мониторинг регистрации голосовых отпечатков зарегистрированных пользователей в системе.
                                                </p>
                                            </Grid>
                                            <Grid item xs={12} md={5} lg={5} >
                                                <img src={banner2}  alt="" />
                                                <h1>Мониторинг регистрации голосовых отпечатков</h1>
                                                <p>
                                                    Прослушивание аудио записи, используемые для построения голосового отпечатка
                                                </p>
                                            </Grid>
                                        </Grid>
                                    </Container> */}
                                    <Container >
                                        <Grid container spacing={4} >
                                            <Grid item xs={"auto"} md={1} lg={1}></Grid>
                                            <Grid item xs={12} md={5} lg={5} style={{textAlign:'left', position: 'relative'}}  >
                                                <h2>Мониторинг голосовых отпечатков</h2>
                                                <br/>
                                                <p>
                                                    > Мониторинг списка голосовых отпечатков пользователей.<br/>
                                                    > Мониторинг статуса построения голосового отпечатка.<br/>
                                                    > Просмотр оцилограмм и прослушивание аудиозаписей голосовых отпечатков.<br/>
                                                </p>
                                            </Grid>
                                            <Grid item xs={12} md={6} lg={6} >
                                                <img src={banner_voiceprint}  alt="" />
                                            </Grid>
                                        </Grid>
                                    </Container>
                                </SwiperSlide>
                                <SwiperSlide>
                                    {/*
                                    <Container >
                                        <Grid container spacing={4} >
                                            <Grid item xs={"auto"} md={1} lg={1}></Grid>
                                            <Grid item xs={12} md={5} lg={5}  style={{textAlign:'left', position: 'relative'}} >
                                                <img src={banner1}  alt="" />
                                                <h1>Мониторинг голосовых аутенфикаций</h1>
                                                <p>
                                                    Мониторинг голосовых аутенфикаций зарегистрированных пользователей в системе.
                                                </p>
                                            </Grid>
                                            <Grid item xs={12} md={5} lg={5} >
                                                <img src={banner2}  alt="" />
                                                <h1>Мониторинг голосовых аутенфикаций</h1>
                                                <p>
                                                    Прослушивание аудио записи, используемая для голосовой аутенфикации.
                                                </p>
                                            </Grid>
                                        </Grid>
                                    </Container> */}
                                    <Container >
                                        <Grid container spacing={4} >
                                            <Grid item xs={"auto"} md={1} lg={1}></Grid>
                                            <Grid item xs={12} md={5} lg={5} style={{textAlign:'left', position: 'relative'}}  >
                                                <h2>Мониторинг голосовых аутентификаций</h2>
                                                <br/>
                                                <p>
                                                    > Мониторинг списка голосовых аутентификаций пользователей.<br/>
                                                    > Прослушивание аудиозаписей голосовых аутентификаций.<br/>
                                                    > Блокировка пользователя при подозрении на фишинг.<br/>
                                                    > Регулируемый уровень порогов ошибок принятия (FAR) и отказа (FRR) для каждого пользователя.<br/>
                                                </p>
                                            </Grid>
                                            <Grid item xs={12} md={6} lg={6} >
                                                <img src={banner_voiceverify}  alt="" />
                                            </Grid>
                                        </Grid>
                                    </Container>
                                </SwiperSlide>
                                <SwiperSlide>
                                    {/*
                                    <Container >
                                        <Grid container spacing={4} >
                                            <Grid item xs={"auto"} md={1} lg={1}></Grid>
                                            <Grid item xs={12} md={5} lg={5}  style={{textAlign:'left', position: 'relative'}} >
                                                <img src={banner1}  alt="" />
                                                <h1>Голосовая аналитика</h1>
                                                <p>
                                                    > Стастика количества зарегистрированных пользователей за выбранный период времени.<br/>
                                                    > Стастика регистрации голосовых отпечатков по всем зарегистрированных пользователей за выбранный период времени.<br/>
                                                    > Стастика голосовых аутенфикаций (принятий и отказов) по всем зарегистрированных пользователей за выбранный период времени.
                                                </p>
                                            </Grid>
                                            <Grid item xs={12} md={5} lg={5} >
                                                <img src={banner2}  alt="" />
                                                <h1>Голосовая аналитика</h1>
                                                <p>
                                                    So you have your new digital camera and clicking away to glory anything and everything in sight. Now you want to print them and you need the best photo
                                                </p>
                                            </Grid>
                                        </Grid>
                                    </Container> */}
                                    <Container >
                                        <Grid container spacing={4} >
                                            <Grid item xs={"auto"} md={1} lg={1}></Grid>
                                            <Grid item xs={12} md={5} lg={5} style={{textAlign:'left', position: 'relative'}}  >
                                                <h2>Аналитика</h2>
                                                <br/>
                                                <p>
                                                    > Количества зарегистрированных пользователей за выбранный период времени.<br/>
                                                    > Количества голосовых отпечатков за выбранный период времени.<br/>
                                                    > Количества голосовых аутентификаций (принятий, отказов) за выбранный период времени.<br/>
                                                </p>
                                            </Grid>
                                            <Grid item xs={12} md={6} lg={6} >
                                                <img src={banner_analytic}  alt="" />
                                            </Grid>
                                        </Grid>
                                    </Container>
                                </SwiperSlide>
                                ...
                            </Swiper>
                        </Grid>
                    </Grid>
                </Container>
            </section>
            <section className={classes.sectionslider2}>
                <Container >
                    <h1>Функциональные характеристики</h1>
                    <Grid container spacing={4} >
                        <Grid item xs={12} md={12} lg={12} >
                            <Swiper
                                className={classes.swiper}
                                spaceBetween={50}
                                slidesPerView={1}
                                navigation={true}
                            >
                                <SwiperSlide>
                                    <Container >
                                        <Grid container spacing={1} >
                                            <Grid item xs={12} md={12} lg={12} style={{textAlign:'left', paddingLeft: 30, position: 'relative'}}  >
                                                <h2>Общие характеристики</h2>
                                                <br/>
                                                <p>
                                                    <li> > Не зависит от языка;</li>
                                                    <li> > Не менее 15 секунд длительность аудио записи с голосовым образцом для получения голосового отпечатка;</li>
                                                    <li> > Не менее 7 секунд длительность аудио записи с голосовым образцом для его верификации;</li>
                                                    <li> > Быстрая адаптация к окружающим шумам и канальным искажениям;</li>
                                                    <li> > Операции с низким уровнем отношения сигнал-шум (SNR);</li>
                                                    <li> > Простота встраивания в целевое мобильное приложение (iOS/Android);</li>
                                                </p>
                                            </Grid>
                                        </Grid>
                                    </Container>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <Container >
                                        <Grid container spacing={2} >
                                            <Grid item xs={12} md={8} lg={8} style={{textAlign:'left', paddingLeft: 30, position: 'relative'}}  >
                                                <h2>Тестовые проекты SDK для Android/iOS платформ</h2>
                                                <br/>
                                                <p>
                                                    <li> > Поддержка установки приложения на Android ver.7.x и выше, наличие свободного места не менее 50 Mb;</li>
                                                    <li> > Поддержка установки приложения на iOS ver.12.x и выше, наличие свободного места не менее 50 Mb;</li>
                                                    <li> > Поддержка сетевого взаимодействия с сервером в рамках REST API протокола (с шифрованием);</li>
                                                    <li> > Регистрация нового пользователя в системе Speaker-ID облачного хранилища данных;</li>
                                                    <li> > Авторизация пользователя в системе с помощью логин/пароля;</li>
                                                    <li> > Авторизация пользователя в системе с помощью голосовой аутентификации;</li>
                                                    <li> > Демонстрация голосового обучения: запись аудио данных с целевым голосом пользователя, длительностью не более 5 минут и передача их в систему Speaker-ID, с целью получения голосового отпечатка пользователя и сохранения полученных данных в облачном хранилище данных;</li>
                                                    <li> > Демонстрация голосовой аутентификации: запись аудио данных с голосом пользователя, длительностью не более 5 минут и передача их в систему Speaker-ID, с целью сравнения переданных аудио данных с его голосовым(ми) отпечатком(ми) и сохранения полученных данных в облачном хранилище данных;</li>
                                                </p>
                                            </Grid>
                                            <Grid item xs={12} md={4} lg={4} >
                                                <img src={banner_phones}  alt="" />
                                            </Grid>
                                        </Grid>
                                    </Container>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <Container >
                                        <Grid container spacing={2} >
                                            <Grid item xs={12} md={11} lg={11} style={{textAlign:'left', paddingLeft: 30, position: 'relative'}}  >
                                                <h2>Web интерфейс личного кабинета владельца Speaker-ID сервиса</h2>
                                                <br/>
                                                <p>
                                                    <li> > Поддержка работоспособности в браузерах Google Chrome, Opera, Fire Fox, Safary;</li>
                                                    <li> > Поддержка web интерфейса на русском и английском языках, предусмотрена возможность добавление нового языка;</li>
                                                    <li> > Регистрация нового владельца через Web интерфейс в системе Speaker-ID;</li>
                                                    <li> > Авторизация владельца через Web интерфейс в системе Speaker-ID с помощью логин/пароля;</li>
                                                    <li> > Просмотр и редактирование профиля владельца;</li>
                                                    <li> > Добавление коллег владельца сервиса Speaker-ID с разграничением по правам доступа (полный доступ, доступ на чтение, блокировка доступа);</li>
                                                    <li> > Просмотр списка профилей пользователей, зарегистрированных под учетной записью владельца системы;</li>
                                                    <li> > Просмотр и редактирование профиля пользователя, активации, блокировки учетной записи выбранного пользователя;</li>
                                                    <li> > Просмотр и прослушивание списка аудио записей пользователей, используемых для  построения голосовых отпечатков;</li>
                                                    <li> > Просмотр и прослушивание списка аудио записей пользователей, используемых для проведения голосовой аутентификации;</li>
                                                    <li> > Добавление аудио записи с целевым голосом пользователя, используемая для построения голосового отпечатка;</li>
                                                    <li> > Добавление аудио записи с голосом пользователя для проведения голосовой аутентификации;</li>
                                                    <li> > Мониторинг голосовых отпечатков и голосовых аутентификаций пользователей;</li>
                                                    <li> > Возможность настройки для каждого пользователя параметров голосовой аутентификации (порог срабатывания ошибок FAR, FRR);</li>
                                                    <li> > Аналитика за выбранный период количества зарегистрированных пользователей;</li>
                                                    <li> > Аналитика за выбранный период количества добавления новых голосовых отпечатков и проведенных голосовых аутентификаций с кличеством принятий и отказов;</li>
                                                </p>
                                            </Grid>
                                            <Grid item xs={12} md={1} lg={1} >
                                                <img src={banner_account}  alt="" />
                                            </Grid>
                                        </Grid>
                                    </Container>
                                </SwiperSlide>
                            </Swiper>
                        </Grid>
                    </Grid>
                </Container>
            </section>
            <section className={classes.sectionslider2}>
                <Container >
                    <h1>Требования</h1>
                    <Grid container spacing={4} >
                        <Grid item xs={12} md={12} lg={12} >
                            <Swiper
                                className={classes.swiper}
                                spaceBetween={50}
                                slidesPerView={1}
                                navigation={true}
                            >
                                <SwiperSlide>
                                    <Container >
                                        <Grid container spacing={1} >
                                            <Grid item xs={12} md={12} lg={12} style={{textAlign:'left', paddingLeft: 30, position: 'relative'}}  >
                                                <h2>Требования для установки мобильного приложения</h2>
                                                <br/>
                                                <p>
                                                    <li> > Android ver.7.x и выше;</li>
                                                    <li> > iOS ver.12.x и выше;</li>
                                                    <li> > Наличие свободного места на диске не менее 100 Mb;</li>
                                                    <li> > Разрешение на доступ к Internet;</li>
                                                    <li> > Разрешение на доступ записи Audio;</li>
                                                </p>
                                            </Grid>
                                        </Grid>
                                    </Container>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <Container >
                                        <Grid container spacing={1} >
                                            <Grid item xs={12} md={12} lg={12} style={{textAlign:'left', paddingLeft: 30, position: 'relative'}}  >
                                                <h2>Требования для запуска Web интерфейса личного кабинета Speaker-ID сервиса</h2>
                                                <br/>
                                                <p>
                                                    <li> > Минимальная кофигурация PC: (Intel Core, CPU 2, RAM 2Gb, Free disk > 100 Mb);</li>
                                                    <li> > Наличие одного из браузера: Google Chrome, Opera, Yandex, FireFox, Safary;</li>
                                                    <li> > Доступ к Internet;</li>
                                                </p>
                                            </Grid>
                                        </Grid>
                                    </Container>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <Container >
                                        <Grid container spacing={1} >
                                            <Grid item xs={12} md={12} lg={12} style={{textAlign:'left', paddingLeft: 30, position: 'relative'}}  >
                                                <h2>Требования для установки сервиса Speaker-ID на сервера заказчика</h2>
                                                <br/>
                                                <p>
                                                    <li> > Наличие 1-го или 2-х серверов для frontEnd/backEnd API и для СУБД PostgreSQL;</li>
                                                    <li> > ОС: Debian, Ubinta, Rocky Linux (Centos 8);</li>
                                                    <li> > Минимальная конфигурация: (Core: Intel Core/Xeon 2.1GHz, CPU: 8, RAM: 8Gb, SWAP: 3Gb, DISK: 500Mb);</li>
                                                    <li> > Доступ к Internet;</li>
                                                    <li> > Скорость сети от 100 Мбит/с;</li>
                                                    <li> > Доступ к серверу по ssh, ftps протоколам;</li>
                                                    <li> > Открытые порты: 22, 80, 443, 3000, 3001, 5432;</li>
                                                </p>
                                            </Grid>
                                        </Grid>
                                    </Container>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <Container >
                                        <Grid container spacing={1} >
                                            <Grid item xs={12} md={12} lg={12} style={{textAlign:'left', paddingLeft: 30, position: 'relative'}}  >
                                                <h2>Требования для эксплуатации</h2>
                                                <br/>
                                                <p>
                                                    <li> > Опыт работы с мобильными приложениями;</li>
                                                    <li> > Опыт работы с web приложениями;</li>
                                                    <li> > Опыт разработки нативных приложений на iOS(swift), Android(kotlin);</li>
                                                    <li> > Опыт развертывания и администривания ОС на базе Linux;</li>
                                                   </p>
                                            </Grid>
                                        </Grid>
                                    </Container>
                                </SwiperSlide>
                            </Swiper>
                        </Grid>
                    </Grid>
                </Container>
            </section>
            <section className={classes.sectionslider2}>
                <Container >
                    <h1>Доступность</h1>
                    <Grid container spacing={2} >
                        <Grid item xs={12} md={12} lg={12} >
                            <Swiper
                                className={classes.swiper}
                                spaceBetween={50}
                                slidesPerView={1}
                                navigation={true}
                            >
                                <SwiperSlide>
                                    <Container >
                                        <Grid container spacing={1} >
                                            <Grid item xs={12} md={12} lg={12} style={{textAlign:'left', paddingLeft: 30, position: 'relative'}}  >
                                                <h2>Варианты поставки</h2>
                                                <br/>
                                                <p>
                                                    <li> > Вариант 1. Через регистрацию в облачном сервисе Speaker-ID;</li>
                                                    <li> > Вариант 2. Через полную установку и развертывание сервиса Speaker-ID на серверах заказчика;</li>
                                                </p>
                                            </Grid>
                                        </Grid>
                                    </Container>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <Container >
                                        <Grid container spacing={1} >
                                            <Grid item xs={12} md={12} lg={12} style={{textAlign:'left', paddingLeft: 30, position: 'relative'}}  >
                                                <h2>Варианты лицензирования</h2>
                                                <br/>
                                                <p>
                                                    <table style={{border: 'solid 1px #2277b7', width: '100%'}}>
                                                        <tr style={{backgroundColor: '#d1e1f4', height: 20}}>
                                                            <td style={{width: '10%', textAlign: 'center' }}> Вариант </td>
                                                            <td style={{width: '15%', textAlign: 'center' }}> Доступность </td>
                                                            <td style={{width: '30%', textAlign: 'center' }}> Стоимость </td>
                                                            <td style={{width: '22%', textAlign: 'center' }}> Поставка </td>
                                                            <td style={{width: '23%', textAlign: 'center' }}> Условия </td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{textAlign: 'center' }}> 1 </td>
                                                            <td> Без ограничений </td>
                                                            <td>1.20 руб. - за каждый созданный голосовой отпечаток длительностью 60 секунд.<br/>
                                                                0.60 руб. - за каждую проведенную голосовую аутентификацию длительностью 60 секунд.
                                                            </td>
                                                            <td>Пакет Speaker-ID Mobile SDK(Android/iOS):<br/>
                                                                 - Тестовые проекты iOS(swift) под xCode IDE, Android(kotlin) под IDE Android Studio,<br/>
                                                                 - Описание API под каждый проект.</td>
                                                            <td> Наличие положительно баланса в личном кабинете облачного сервиса Speaker-ID </td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{textAlign: 'center' }}> 2 </td>
                                                            <td> Без ограничений </td>
                                                            <td> По требованию </td>
                                                            <td>Пакет Speaker-ID Mobile SDK(Android/iOS):<br/>
                                                                - Тестовые проекты iOS(swift) под xCode IDE, Android(kotlin) под IDE Android Studio,<br/>
                                                                - Описание API под каждый проект.<br/>
                                                                Установка и развертывание облачного сервиса Speaker-ID на серверах заказчика.
                                                            </td>
                                                            <td> Полная предоплата</td>
                                                        </tr>
                                                    </table>

                                                </p>
                                            </Grid>
                                        </Grid>
                                    </Container>
                                </SwiperSlide>
                            </Swiper>
                        </Grid>
                    </Grid>
                </Container>
            </section>
            <section className={classes.sectionmarket}>
                <Container >
                    <Grid container spacing={5} >
                        <Grid item xs={12} md={5} lg={5} >
                            <h2>Протестируйте сейчас "Speaker-id check"</h2>
                            <br/><br/>
                            <a href="https://play.google.com/store/apps/details?id=com.grittec.speakerid" target="_blank" rel="noreferrer" >
                                <img src={googleplay} alt="" />
                            </a>
                            &nbsp;&nbsp;&nbsp;
                            <a href="https://apps.rustore.ru/app/com.grittec.speakerid" target="_blank" rel="noreferrer" >
                                <img src={rustore} alt="" />
                            </a>
                            &nbsp;&nbsp;&nbsp;
                                <img src={appstore} alt="" />
                            <br/><br/>
                        </Grid>
                        <Grid item xs={12} md={7} lg={7} style={{textAlign:'right', position: 'relative'}} >
                            <img src={smartphone} alt="" />
                        </Grid>
                    </Grid>
                </Container>
            </section>
        </div>;
    };
}


export default withStyles(useStyles, {withTheme: true})(Rus);