import {IN_USER} from "../actions/types";

const initialState = {
    name: "Name User",
    counter: 0
}

export default function userReducers(state = initialState, action) {
    switch(action.type) {
        case IN_USER:
            return action.payload;
        default:
            return state
    }
}