import React, {Component} from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import { DropzoneArea } from 'material-ui-dropzone'
import PropTypes from "prop-types";
import TextField from "@material-ui/core/TextField/TextField";
import Grid from "@material-ui/core/Grid";
import moment from "moment";
import 'moment/locale/ru';
import Alert from '@material-ui/lab/Alert';
import {Trans} from "react-i18next";
moment.locale('ru');

export default class DialogUpload extends Component {
  constructor(props){
    super(props);
    this.state = {
      file: [],
      comments: null
    };
  }


  render() {
    return (
        <>
          <Dialog
              open={this.props.isOpen}
              onClose={this.props.handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
          >
            <form onSubmit={(event) => {
              event.preventDefault();
              this.props.handleSave(this.state);
            }}>
              <DialogTitle id="alert-dialog-title">{this.props.title}</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  {this.props.subtitle}
                </DialogContentText>
                {this.props.errorUpload ? <Alert severity="error" style={{marginBottom: 25}} >{this.props.errorUpload}</Alert>:''}
                {/*<TextField

                    required
                    onChange={(event) => this.setState({duration: event.target.value}) }
                    value={this.state.duration}
                    style={{marginBottom: 25}}
                    variant="outlined"
                    id = "duration"
                    name = "duration"
                    label = {<Trans i18nKey={"DurationSecond"} >{"DurationSecond"}</Trans>}
                    type = "number"
                    fullWidth
                />
                <TextField
                    id="datetime-local"
                    style={{marginBottom: 25}}
                    label={<Trans i18nKey={"DateAndTime"} >{"DateAndTime"}</Trans>}
                    type="datetime-local"
                    onChange={(event) => this.setState({study_time: event.target.value}) }
                    defaultValue={this.state.study_time}

                    InputLabelProps={{
                      shrink: true,
                    }}
                />*/}
                <DropzoneArea
                  acceptedFiles={this.props.acceptedFiles}
                  showPreviewsInDropzone={false}
                  dropzoneText={<Trans i18nKey={"DragAndDropAFileHereOrClick"} >{"DragAndDropAFileHereOrClick"}</Trans>}
                  filesLimit={1}
                  onChange={(file) => this.setState({file:file[0]})}
                />
                <TextField
                    multiline={true}
                    required
                    onChange={(event) => this.setState({comments: event.target.value}) }
                    style={{marginBottom: 25}}
                    minRows="5"
                    maxRows="5"
                    Rows="5"
                    variant="outlined"
                    id = "comment"
                    name = "comment"
                    label = {<Trans i18nKey={"Comment"} >{"Comment"}</Trans>}
                    type = "text"
                    fullWidth
                />
              </DialogContent>
              <DialogActions>
                <Button variant="outlined" color="secondary" onClick={(event) => this.props.handleClose()} >
                  Закрыть
                </Button>

                <Button variant="outlined" type="submit" color="primary" >
                  Загрузить
                </Button>
              </DialogActions>
            </form>
          </Dialog>
        </>
    );
  }


}

DialogUpload.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleSave: PropTypes.func.isRequired,
  title: PropTypes.string,
  subtitle: PropTypes.string,
};
