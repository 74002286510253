import React, {Component} from "react";
import { useStyles } from './styles';
import {Dialog, DialogActions, DialogContent, DialogTitle, withStyles} from "@material-ui/core";
import { DataGrid, GridRowsProp, GridColDef } from '@mui/x-data-grid';
import {ruRU} from '@material-ui/core/locale';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import Avatar from '@material-ui/core/Avatar';
import ClearRoundedIcon from '@material-ui/icons/ClearRounded';
import CheckRoundedIcon from '@material-ui/icons/CheckRounded';
import Client from '../../store/Model/Client';
import apiStore from "../../store/ApiStore";
import moment from "moment";
import {millisecondsToHms} from '../../lib/helper';
import Audio from "../Audio";
import SearchIcon from '@material-ui/icons/Search';
import Pagination from '@material-ui/lab/Pagination';
import TablePagination from '@material-ui/core/TablePagination';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputBase from "@material-ui/core/InputBase/InputBase";
import {Trans} from "react-i18next";
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import { getVerifys } from '../../actions/verifyActions'
import { connect } from "react-redux";
import SearcherField from "../shared/SearcherField/SearcherField";
import Button from "@material-ui/core/Button";
import DateRangeIcon from '@material-ui/icons/DateRange';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import Grid from "@material-ui/core/Grid";
import DatePickerRange from "../shared/DatePickerRange/DatePickerRange";
import i18n from "../../services/i18n";

const theme = createTheme(
	{
		palette: {
			primary: { main: '#fff' },
		},
		root: {

		},

		cell: {
			border:0
		}
	},
	ruRU,
);

const BootstrapInput = withStyles((theme) => ({
	root: {
		'label + &': {
			marginTop: theme.spacing(3),
		},
	},
	input: {
		borderRadius: 4,
		position: 'relative',
		backgroundColor: theme.palette.background.paper,
		border: '1px solid #ced4da',
		fontSize: 16,
		padding: '10px 26px 10px 12px',
		transition: theme.transitions.create(['border-color', 'box-shadow']),
		// Use the system font instead of the default Roboto font.
		fontFamily: [
			'-apple-system',
			'BlinkMacSystemFont',
			'"Segoe UI"',
			'Roboto',
			'"Helvetica Neue"',
			'Arial',
			'sans-serif',
			'"Apple Color Emoji"',
			'"Segoe UI Emoji"',
			'"Segoe UI Symbol"',
		].join(','),
		'&:focus': {
			borderRadius: 4,
			borderColor: '#80bdff',
			boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
		},
	},
}))(InputBase);

class VeriflList extends Component {

	state = {
		loading: false,
		rows: [],
		selectRow: null,
        totalRowCount: 40,
        rowsPerPageOptions: [10, 20, 50, 100],
		pageSize: 10,
		page: 1,
		arList: [],
		arUsers: [],
		select: null,
		search: null,
		filter: {
			fio: null,
			finished: -1,
		},
		orderBy: 'study_time',
		sortDesc: true,
		error: {
			isErrorDisplayed: false,
			errorMessage: ''
		},
        isOpenDateDialog: false,
        toDateDefault: moment(new Date()).format("YYYY-MM-DD") , //"2024-05-21"
        fromDateDefault: moment(new Date()).format("YYYY-MM-DD"),//"2024-05-21"
        fromDatePrevios: null,
        toDatePrevios: null,
        fromDate: null,
        toDate: null
	};

	login = '';
	password = '';

	handleSearchChange = (event) => {
		let { name, value } = event.target;
		// console.log("handleSearchChange: ", value);
		this.setState({search:value, page: 0, orderBy: 'study_time', sortdesc: true});
        this.loadList(value, this.state.fromDate, this.state.toDate);
    };

	handleSearchKeyDown = (event) => {
		let { name, value } = event.target;
		// console.log('handleSearchKeyDown: ', value);
		if (event.key  === "Enter") {
			// console.log('handleSearchKeyDown: Enter');
			// request api with search
			this.setState({search:value});
            this.loadList(value, this.state.fromDate, this.state.toDate);
        }
	};

	handleSearchClick = () => {
		// console.log('handleSearchClick: ', this.state.search);
		// request api with search
        this.loadList(this.state.search, this.state.fromDate, this.state.toDate);
    };

	handleClearAllClick = () => {
		// this.setState({search:"", page: 0, orderBy: 'study_time', sortdesc: true});
		// this.loadList(this.state.search);
        this.setState({
            search:"", page: 0, orderBy: 'study_time', sortdesc: true, fromDate: null, toDate: null
        }, () => {
            // console.log("handleClearAllClick: ", this.state.search);
            this.loadList(this.state.search, this.state.fromDate, this.state.toDate);
        })
	};

    handleSaveDateDialog = () => {
        // console.log("handleSaveDateDialog: ");
        this.setState({
            isOpenDateDialog: false,
            fromDatePrevios: this.state.fromDate,
            toDatePrevios: this.state.toDate
        }, () => {
            this.loadList(this.state.search, this.state.fromDate, this.state.toDate);
        })
    };
    handleCloseDateDialog = () => {
        // console.log("handleCloseDateDialog: ");
        this.setState({
            isOpenDateDialog: false,
            fromDate: this.state.fromDatePrevios,
            toDate: this.state.toDatePrevios
        });
    };
    handleClickDateDialog = () => {
        // console.log("handleClickDateDialog: ");
        this.setState({
            isOpenDateDialog: true,
            fromDate: this.state.fromDateDefault,
            toDate: this.state.toDateDefault
        });
    };

    handleSetDateFrom = (value) => {
        // console.log("handleSetDateFrom, value: ", value);
        this.setState({fromDate: value});
        if(new Date(value).getTime() > new Date(this.state.toDate).getTime())  {
            this.setState({toDate: value});
        }
    };
    handleSetDateTo = (value) => {
        // console.log("handleSetDateTo, value: ", value);
        this.setState({toDate: value});
        if(new Date(value).getTime() < new Date(this.state.fromDate).getTime())  {
            this.setState({fromDate: value});
        }
    };

	columns = [
		{ field: "result", headerName: <Trans i18nKey={"Status"} >{"Status"}</Trans>, width: 150, GridColType: 'boolean', renderCell: (params) => {
				switch (params.row.result) {
					case 'reject':
						return (
							<div className="d-flex justify-content-between align-items-center" style={{ cursor: "pointer" }}>
								<Chip index={params.row.id} className={'deactive'} avatar={<Avatar><ClearRoundedIcon /></Avatar>} variant="outlined" label={<Trans i18nKey={"Rejected"} >{"Rejected"}</Trans>} />
							</div>
						);
					case 'accept':
						return (
							<div className="d-flex justify-content-between align-items-center" style={{ cursor: "pointer" }}>
								<Chip index={params.row.id} className={'active'} avatar={<Avatar><ClearRoundedIcon /></Avatar>} variant="outlined" label={<Trans i18nKey={"Accepted"} >{"Accepted"}</Trans>} />
							</div>
						);
					case 'error':
						return (
							<div className="d-flex justify-content-between align-items-center" style={{ cursor: "pointer" }}>
								<Chip index={params.row.id} className={'deactive'} avatar={<Avatar><ClearRoundedIcon /></Avatar>} variant="outlined" label={<Trans i18nKey={"Error"} >{"Error"}</Trans>} />
							</div>
						);
					default:
						return '';
				}
			},
			renderHeader: (props) => {
				return  <>
					<span  onClick={(event) => this.setState({orderBy: 'status', sortDesc: (this.state.orderBy == null ? true : !this.state.sortDesc)}, this.loadList)} >
						<Trans i18nKey={"Status"} >{"Status"}</Trans>
						{this.state.orderBy === 'status' ? <>{this.state.sortDesc ? <ArrowDownwardIcon/> : <ArrowUpwardIcon/>}</>: ''}
					</span>
					<FormControl variant="standard" className={"input"}>
						<Select
							variant={"standard"}
							className={"underlineselect"}
							displayEmpty
							value={""}
							onChange={
								event	=>
								{
									let filter = this.state.filter;
									filter['status'] = event.target.value;
									this.setState({filter:filter});
								}
							}
							input={<BootstrapInput />}
						>
							<MenuItem value="" key="DoesNotMatter" >
								<Trans i18nKey={"DoesNotMatter"}>{"DoesNotMatter"}</Trans>
							</MenuItem>
							<MenuItem value={"reject"} key="reject" ><Trans i18nKey={"Rejected"} >{"Rejected"}</Trans></MenuItem>
							<MenuItem value={"error"} key="error" ><Trans i18nKey={"Error"} >{"Error"}</Trans></MenuItem>
							<MenuItem value={"accept"} key="accept" ><Trans i18nKey={"Accepted"} >{"Accepted"}</Trans></MenuItem>
						</Select>

						<label htmlFor="status"><Trans i18nKey={"Status"}>{"Status"}</Trans></label>
					</FormControl>
				</>
			},
			sortable: false
		},
		{ field: "user_u", headerName: <Trans i18nKey={"Fio"} >{"Fio"}</Trans>, width: 350, renderCell: (params) => {
				return [params.row.lastname, params.row.firstname, params.row.middlename]
					.filter(val => val ? val.length>0 : "")
					.join(' ');
			},
			renderHeader: (props) => {
				return  <>
					<span onClick={(event) => this.setState({orderBy: 'finished', sortDesc: (this.state.orderBy == null ? true : !this.state.sortDesc)}, this.loadList)} >
						<Trans i18nKey={"FIO"} >{"FIO"}</Trans>
						{this.state.orderBy === 'finished' ? <>{this.state.sortDesc ? <ArrowDownwardIcon/> : <ArrowUpwardIcon/>}</>: ''}
					</span>
					<div className={"input"}>
						<input type={"text"} name={"fio"} id="fio" placeholder=" " onChange={
							(event) => {
								let filter = this.state.filter;
								filter['fio'] = event.target.value;
								this.setState({filter:filter});
							}
						} />
						<label htmlFor="fio"><Trans i18nKey={"DoesNotMatter"}>{"DoesNotMatter"}</Trans></label>
					</div>
				</>
			},
			sortable: false
		},
		{ field: "link", headerName: <Trans i18nKey={"FileName"} >{"FileName"}</Trans>, width: 250, renderCell: (params) => {
				return <Audio
					style={{width: 250, position: 'absolute', zIndex: 1, marginLeft: -10}}
					controls
					type={"verify"}
					// file={params.row}
					file={ ((row) => {
						let file = {link: null};
						file.link = row.link;
						file.uid = row.uid;
						return file;
					})(params.row) }
				/>;
			},
			renderHeader: (props) => {
				return  <>
					<span onClick={(event) => this.setState({orderBy: 'link', sortDesc: (this.state.orderBy == null ? true : !this.state.sortDesc)}, this.loadList)} >
						<Trans i18nKey={"FileName"} >{"FileName"}</Trans>
						{this.state.orderBy === 'link' ? <>{this.state.sortDesc ? <ArrowDownwardIcon/> : <ArrowUpwardIcon/>}</>: ''}
					</span>
					<div className={"input"}>
						<input type={"text"} name={"link"} id="link" placeholder=" " onChange={
							(event) => {
								let filter = this.state.filter;
								filter['link'] = event.target.value;
								this.setState({filter:filter});
							}
						} />
						<label htmlFor="link"><Trans i18nKey={"DoesNotMatter"}>{"DoesNotMatter"}</Trans></label>
					</div>
				</>
			},
			sortable: false
		},
        { field: "study_time", headerName: <Trans i18nKey={"Date"} >{"Date"}</Trans>, width: 150, renderCell: (params) => {
                return moment(params.row.study_time).format("YYYY-MM-DD HH:mm:ss");
            }, sortable: false,
            renderHeader: (props) => {
                return  <>
					<span onClick={(event) => this.setState({orderBy: 'study_time', sortDesc: (this.state.orderBy === null ? true : !this.state.sortDesc)}, this.loadList)} >
                        <Trans i18nKey={"Date"} >{"Date"}</Trans>
                        {this.state.orderBy === 'study_time' ? <>{this.state.sortDesc ? <ArrowDownwardIcon/> : <ArrowUpwardIcon/>}</>: ''}
					</span>
                    <div className={"input"}>

                    </div>
                </>
            }
        },
		{ field: "duration", headerName: <Trans i18nKey={"Duration"} >{"Duration"}</Trans>, width: 150, renderCell: (params) => {
				return millisecondsToHms(params.row.duration);
			},
			renderHeader: (props) => {
				return  <>
					<span onClick={(event) => this.setState({orderBy: 'duration', sortDesc: (this.state.orderBy == null ? true : !this.state.sortDesc)}, this.loadList)} >
						<Trans i18nKey={"Duration"} >{"Duration"}</Trans>
						{this.state.orderBy === 'duration' ? <>{this.state.sortDesc ? <ArrowDownwardIcon/> : <ArrowUpwardIcon/>}</>: ''}
					</span>
					<div className={"input"}>
					</div>
				</>
			},
			sortable: false
		},
		{ field: "far", headerName: <Trans i18nKey={"FA"} >{"FA"}</Trans>, width: 80,
			renderHeader: (props) => {
				return  <>
					<span onClick={(event) => this.setState({orderBy: 'far', sortDesc: (this.state.orderBy == null ? true : !this.state.sortDesc)}, this.loadList)} >
						<Trans i18nKey={"FA"} >{"FA"}</Trans>
						{this.state.orderBy === 'far' ? <>{this.state.sortDesc ? <ArrowDownwardIcon/> : <ArrowUpwardIcon/>}</>: ''}
					</span>
					<div className={"input"}>
					</div>
				</>
			},
			sortable: false
		},
		{ field: "frr", headerName: <Trans i18nKey={"FR"} >{"FR"}</Trans>, width: 80,
			renderHeader: (props) => {
				return  <>
					<span onClick={(event) => this.setState({orderBy: 'frr', sortDesc: (this.state.orderBy == null ? true : !this.state.sortDesc)}, this.loadList)} >
						<Trans i18nKey={"FR"} >{"FR"}</Trans>
						{this.state.orderBy === 'frr' ? <>{this.state.sortDesc ? <ArrowDownwardIcon/> : <ArrowUpwardIcon/>}</>: ''}
					</span>
					<div className={"input"}>
					</div>
				</>
			},
			sortable: false
		},
		{ field: "signal_snr",width: 80,  headerName: <Trans i18nKey={"SNR_DB"} >{"SNR_DB"}</Trans>, sortable: false,
			renderHeader: (props) => {
				return  <>
					<span  onClick={(event) => this.setState({orderBy: 'signal_snr', sortDesc: (this.state.orderBy === null ? true : !this.state.sortDesc)}, this.loadList)} ><Trans i18nKey={"SNR_DB"} >{"SNR_DB"}</Trans>
						{this.state.orderBy === 'signal_snr' ? <>{this.state.sortDesc ? <ArrowDownwardIcon/> : <ArrowUpwardIcon/>}</>: ''}
					</span>
					<div className={"input"}>

					</div>
				</>
			}
		},
	];

    loadList = async (search = null, fromDate = null, toDate = null) => {
        // await apiStore.getClients((this.state.page * 1 - 1), this.state.pageSize).then(data => data.data).then(data => data.map(val=>new Client(val))).then(data => this.setState({arUsers: data}));
        //await apiStore.getVoiceEnrollAll(null, null, this.state.page * 1 - 1, this.state.pageSize, this.state.orderBy, this.state.sortDesc).then(data => this.setState({arList: data.data.map(val=>{ val["id"] = val.uid; return val;}), totalRowCount: data.meta.quantity*1}));
        await this.props.getVerifys(fromDate, toDate, this.state.page * 1 - 1, this.state.pageSize, search, this.state.orderBy, this.state.sortDesc).items
            .then(data => this.setState({arList: data.data.map(val=>{ val["id"] = val.uid; return val;}), totalRowCount: data.meta.quantity*1}));
    }

	customPagination = () => {

		return (<>
				<div style={{float: 'left'}} >
					<div  >
						<Pagination
							color="primary"
							variant="outlined"
							shape="rounded"
							count={Math.round(this.state.totalRowCount / this.state.pageSize) + ((this.state.totalRowCount % this.state.pageSize>0)?0:1)}
							page={this.state.page}
							onChange={(event, value) => {
							//	console.log("page: ", value);
								this.setState({page: value * 1 }, () => this.loadList(this.state.search))
							}}

						/>
					</div>
					<div style={{marginTop: '10px'}} >
						({(this.state.page-1) * this.state.pageSize} - {this.state.page * this.state.pageSize < this.state.totalRowCount?(this.state.page * this.state.pageSize): this.state.totalRowCount}) / {this.state.totalRowCount}
					</div>
				</div>
				<div>
					<div style={{color: '#828d99', float:'left', marginRight: 10}}><Trans i18nKey={"NumberRecords"} >{"NumberRecords"}</Trans><br/>
						<Trans i18nKey={"OnPage"} >{"OnPage"}</Trans>:</div>
					<Select
						style={{}}
						value={this.state.pageSize}
						onChange={event=>{
						    //console.log("onChange, pageSize: ", event.target.value);
						    this.setState({page: 0, pageSize: event.target.value}, () => this.loadList(this.state.search))}
						}
						input={<BootstrapInput />}
					>
						{this.state.rowsPerPageOptions.map(val=><MenuItem value={val} key={"NumberRecords " + val}>{val}</MenuItem>)}
					</Select>
				</div>
			</>
		);
	}

	onFilterModelChange = (model) => {
	//	for(let item of model.items){
	//		console.log(item);
	//	}
	}

	componentDidMount() {
		this.loadList();
	}

    renderDateDialog = () => {
        const {classes} = this.props;
        return <Dialog
            open={this.state.isOpenDateDialog}
            onClose={this.handleCloseDateDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth={'md'}
        >
            <DialogTitle id="alert-dialog-title"><Trans i18nKey={"DateSelect"} >{"DateSelect"}</Trans></DialogTitle>
            <DialogContent>
                {/*<DialogContentText id="alert-dialog-description">
					</DialogContentText>*/}
                <Grid container  spacing={2} >
                    <Grid item xs={12} md={4} lg={6} >
                        <FormControl className={classes.formControl}>
                            <DatePickerRange
                                language={i18n.language}
                                label={<Trans i18nKey={"DateFrom"} >{"DateFrom"}</Trans>}
                                defaultValue={
                                    new Date(this.state.fromDateDefault).getTime() < new Date(this.state.toDate).getTime()
                                        ? this.state.fromDateDefault : this.state.toDate
                                }
                                updateValue={this.handleSetDateFrom}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={4} lg={6} >
                        <FormControl className={classes.formControl}>
                            <DatePickerRange
                                language={i18n.language}
                                label={<Trans i18nKey={"DateTo"} >{"DateTo"}</Trans>}
                                defaultValue={
                                    new Date(this.state.toDateDefault).getTime() > new Date(this.state.fromDate).getTime()
                                        ? this.state.toDateDefault : this.state.fromDate
                                }
                                updateValue={this.handleSetDateTo}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" onClick={this.handleCloseDateDialog} color="secondary" >
                    <Trans i18nKey={"Close"} >{"Close"}</Trans>
                </Button>
                <Button variant="outlined" onClick={this.handleSaveDateDialog} color="primary" autoFocus>
                    <Trans i18nKey={"Save"} >{"Save"}</Trans>
                </Button>
            </DialogActions>
        </Dialog>
    };

	render() {
		//this.state.rows.slice(0, this.state.pageSize)
		const {classes} = this.props;

		return (
			<>
                {this.renderDateDialog()}

				<h2 style={{marginTop: -30, marginBottom: 20, marginLeft: 20}}><Trans i18nKey={"VoiceAuthenticationList"} >{"VoiceAuthenticationList"}</Trans></h2>
				<SearcherField
					name="searcher"
					label={<Trans i18nKey={"Search"} >{"Search"}</Trans>}
					style={{marginTop: 0, marginLeft: 10, marginBottom: 24, width: '47%'}}
					value={this.state.search}
					onChange={this.handleSearchChange}
					onKeyDown={this.handleSearchKeyDown}
					onClick={this.handleSearchClick}
					isErrorDisplayed={this.state.error.isErrorDisplayed}
					errorMessage={this.state.error.errorMessage}
				/>

                <Button variant="outlined"
                        endIcon = {<DateRangeIcon/>}
                        style={{marginTop: 0, marginLeft: 40, marginBottom: 24}}
                        onClick={this.handleClickDateDialog} color="primary" >
                    {this.state.fromDate ? moment(this.state.fromDate).format("YYYY-MM-DD") : ""}
                    &nbsp; - &nbsp;
                    {this.state.toDate ? moment(this.state.toDate).format("YYYY-MM-DD") : ""}
                </Button>
                <Button variant="outlined"
                        startIcon = {<FilterAltOffIcon/>}
                        style={{marginTop: 0, marginLeft: 10, marginBottom: 24}}
                        onClick={this.handleClearAllClick} color="primary" >
                    <Trans i18nKey={"Clear"} >{"Clear"}</Trans>
                </Button>

				<ThemeProvider
					theme={theme}
				>
					<div className={classes.root} >
						<DataGrid
							rows={this.state.arList}
							columns={this.columns}
							components={{
								//Toolbar: GridToolbar,
								FilterPanel: null,
								Pagination: this.customPagination
							}}
							autoHeight
							rowHeight={50}
							//
							pagination
							paginationMode="server"
							loading={this.state.loading}
							onFilterModelChange = {this.onFilterModelChange}
							//rowCount={this.state.totalRowCount}
							//rowsPerPageOptions={this.state.rowsPerPageOptions}
							//page={this.state.page-1}
							//pageSize={this.state.pageSize}

							onSelectionModelChange={(data) => {
								data = data.shift();
								let selectData = null;
								data = this.state.arList.map((val) => {
									if(val.id === data){
										selectData = val;
									}
									return val;
								});
								// console.log("onSelectionModelChange, selectData: ", selectData);
								this.props.onSelectUser(selectData);
							}}

							onPageChange={(data) => {
                                // console.log("page2: ", data);
                                this.setState({page: data * 1 }, () => this.loadList(this.state.search));
							}}
							onPageSizeChange={(data) => {
								// console.log("pageSize2: ", data);
								this.setState({page: 0, pageSize: data * 1}, () => this.loadList(this.state.search));
							}}
						/>
					</div>
				</ThemeProvider>
			</>
		);
	}
}


const mapStateToProps = state => {
	return state;
}
const mapDispatchToProps = {
	getVerifys
}

export default withStyles(useStyles)(connect(mapStateToProps, mapDispatchToProps)(VeriflList));
