import React, {Component} from "react";
import {withStyles} from "@material-ui/core";
import PlayCircleFilledWhiteIcon from '@material-ui/icons/PlayCircleFilledWhite';
import PauseCircleFilledIcon from '@material-ui/icons/PauseCircleFilled';
import apiStore from "../../store/ApiStore";
// import Wavesurfer from "react-wavesurfer.js";
import Khz10 from '../../static/wav/demo.wav';
import Slider from '@material-ui/core/Slider';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import ZoomOutIcon from '@material-ui/icons/ZoomOut';
import iconsBasicScrool from '../../static/img/icons-basic-scrool-n.svg';
import WaveSurfer from 'wavesurfer.js';
import WaveSurferTimeline from 'wavesurfer.js/dist/plugin/wavesurfer.timeline';
import 'moment/locale/ru';
import moment from 'moment';

const useStyles = {
  root: {
    border:0,
    overflowX: 'auto',
    backgroundColor: 'white',
    position: 'relative',

    height: 228
  },
  wrapperwave: {
      height: 228,
      marginLeft: 50,
  },
  wrappercontrol: {
    float: 'left',
    width: 30,
    marginRight: 10,
      backgroundColor: '#f7f9fb'
  },
  wave: {
      width: 10000,
      backgroundColor: 'red'
  },
  slider: {
      height: 10,
      float: 'left',
      position: 'absolute',
      left: 0,
      top: 8
  },
  icon: {
      cursor: 'pointer',
      color: '#2277b7',
      marginLeft: 3
  },
  waveform: {
  },
  timeline: {
  }
};

const CSlider = withStyles({
    root: {
        color: '#52af77',
        height: '168px !important',
        width: 10
    },
    thumb: {
        height: 16,
        width: 16,
        borderRadius: 4,
        border: 0,
        backgroundColor: '#fff',
        backgroundImage: "url("+iconsBasicScrool + ")",
        backgroundPosition: 'center center',
        marginTop: -8,
        marginLeft: '-7px !important',
        '&:focus, &:hover, &$active': {
            boxShadow: 'inherit',
        },
    },
    active: {},
    valueLabel: {
        left: 'calc(-50% + 4px)',
        display: 'none'
    },
    track: {
        height: 90,
        borderRadius: 4,
        width: 10,
        backgroundColor: '#a1b3c6'
    },
    rail: {
        height: 90,
        borderRadius: 4,
        width: 10,
        backgroundColor: '#a1b3c6'
    },
})(Slider);

class Waveform extends Component {
    track = null;
  state = {
    play: false
  };


  constructor(props) {
      super(props);
      this.state = {
          loading: false,
          file: [],
          play: false,
          data: null,
          progress: 0,
          zoomMax: 500,
          zoomMin: 50,
          zoomLevel: 100,
          isErrorDisplayed: this.props.isErrorDisplayed,
          errorMessage: this.props.errorMessage
      }
  }
    componentWillReceiveProps(nextProps, nextContext) {
      this.setState({data: null}, () => this.load());
    }

    componentDidMount() {
        this.track = document.querySelector('#track');
        this.waveform = WaveSurfer.create({
            barWidth: 3,
            cursorWidth: 1,
            container: '#waveform',
            backend: 'WebAudio',
            height: 190,
            progressColor: '#2D5BFF',
            responsive: true,
            waveColor: '#124a91',// '#dfdfdf',
            cursorColor: 'transparent',
            interact:false
        });
        this.waveform.on('ready', (event) => {
            const timeline = new WaveSurferTimeline(
                {
                    container: '#timeline',
                    wavesurfer: this.waveform,
                    notchPercentHeight: 20,
                    height: 10,
                    fontSize: 13,
                    color: '#304156',
                    formatTimeCallback: (seconds) => {
                        // console.log("startDateTime: ", this.props.startDateTime);
                        let dateMoment = moment(this.props.startDateTime);
                        dateMoment = dateMoment.add(seconds, "second");
                        return dateMoment.format('hh:mm:ss');
                    }
                }, this.waveform);
            timeline.init();
        });
        this.load();
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.isErrorDisplayed !== this.props.isErrorDisplayed) {
            this.setState({
                isErrorDisplayed: this.props.isErrorDisplayed,
                errorMessage: this.props.errorMessage
            })
        }
        // At on change
        if (prevProps.uid !== this.props.uid) {
            // reset setting
            this.setState({
                zoomLevel: 100
            });
        }
    }

  onChangeCommitted = (event, value) => {
      this.setState({zoomLevel: value}, () => {
          this.waveform.zoom(this.state.zoomLevel);
      })
  };

    blobToBase64Async = async (blob) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.onerror = (e) => reject(fileReader.error);
            fileReader.onloadend = (e) => {
                const dataUrl = fileReader.result;
                // remove "data:mime/type;base64," prefix from data url
                const base64 = "data:audio/wav;base64," + dataUrl.substring(dataUrl.indexOf(',') + 1);
                resolve(base64);
            };
            fileReader.readAsDataURL(blob);
        });
    };

    load = async () => {
        if(1) {
            if(this.props.type === 'enroll') {
                // console.log("Waveform, enroll file uid: ", this.props.uid);
                await apiStore.getEnrollFile(this.props.uid).then(data => data.blob()).then(data => {
                    return this.blobToBase64Async(data);
                }).then(data => {
                    this.setState({data: data}, () => {
                        if(this.state.data)
                            this.waveform.load(this.track);
                    })
                });
            }
            else {
                // console.log("Waveform, verify file uid: ", this.props.uid);
                await apiStore.getVerifyFile(this.props.uid).then(data => data.blob()).then(data => {
                    return this.blobToBase64Async(data);
                }).then(data => {
                    this.setState({data: data}, () => {
                        if(this.state.data)
                            this.waveform.load(this.track);
                    })
                });
            }
        }
    };

    renderCurve() {
        const {classes} = this.props;
        return <div className={classes.root} >
            <div className={classes.wrappercontrol} >
                <ZoomInIcon className={classes.icon} onClick={event => this.setState({zoomLevel: (this.state.zoomLevel + 10)}, () => {
                    this.waveform.zoom(this.state.zoomLevel);
                })} />
                <CSlider
                    orientation="vertical"
                    valueLabelDisplay="auto"

                    value={this.state.zoomLevel}
                    barWidth={10}
                    onChangeCommitted={this.onChangeCommitted}
                    max={this.state.zoomMax}
                    min={this.state.zoomMin}
                />
                <ZoomOutIcon  className={classes.icon}  onClick={event => this.setState({zoomLevel: (this.state.zoomLevel - 10)}, () => {
                    this.waveform.zoom(this.state.zoomLevel);
                })} />
            </div>
            <div className={classes.wrapperwave} >
                <div id="timeline" className={classes.timeline} />
                <div id="waveform"  className={classes.waveform} />
                <audio id="track" src={this.state.data} />

                {/*''&&<Wavesurfer
                    barWidth={2}
                    barHeight={1}
                    barGap={null}
                    src={Khz10}
                    waveColor='#dfdfdf'
                    zoom={this.state.zoomLevel}
                    height={228}
                    interact={false}

                ></Wavesurfer>*/}
            </div>
        </div>;
    }
  render() {
        const {classes} = this.props;
        return this.renderCurve();
  }
}

export default withStyles(useStyles)(Waveform);
