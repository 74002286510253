import React, {Component} from 'react';
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import AddPhotoDropzoneArea from "../AddPhotoDropzoneArea";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import TextFieldValidator from "../TextFieldValidator";
import userStore from "../../../store/UserStore";
import {withSnackbar} from "notistack";

class UserDialogForm extends Component {
	state = {
		checkboxIsDoctor: true,
		checkboxIsOrganisation: false,

		fileInput: [],

		values: {
			inputLastname: "",
			inputFirstname: "",
			inputMiddlename: "",
			inputLogin: "",
			inputPassword: "",

		},

		errors: {
			inputLastname: false,
			inputFirstname: false,
			inputMiddlename: false,
			inputLogin: false,
			inputPassword: false,
		},

		checkIsRequiredFieldsFilled: false,
		isFormValidated: true,
	}

	handleFileChange = (value) => {
		this.setState({
			fileInput: value
		})
	}

	validateChild = (textFieldName, textFieldValue, isErrorDisplayed) => {
		this.setState((prevState) => {
			return {
				errors: {
					...prevState.errors,
					[textFieldName]: isErrorDisplayed,
				},
				values: {
					...prevState.values,
					[textFieldName]: textFieldValue,
				}
			}
		}, () => {
			this.validateForm()
			this.setState({ checkIsRequiredFieldsFilled: false })
		})
	}

	validateForm = () => {
		let errorsCounter = 0
		for (let fieldName in this.state.errors) {
			if (this.state.errors[fieldName]) {
				errorsCounter++
			}
		}

		this.setState(errorsCounter > 0
			? { isFormValidated: false }
			: { isFormValidated: true }
		)
	}

	submitForm = () => {
		this.setState({ checkIsRequiredFieldsFilled: true })

		let { inputLastname, inputFirstname, inputMiddlename, inputLogin, inputPassword } = this.state.values

		let doctorDataOptional = {
			doctor: {
				firstname: inputFirstname,
				lastname: inputLastname,
				middlename: inputMiddlename,
			},
			user: {
				login: inputLogin,
				password: inputPassword
			},
		}

		for (let key in doctorDataOptional.doctor) {
			if (doctorDataOptional.doctor[key] === "" || doctorDataOptional.doctor[key] === this.props.initialFormData[key]) {
				delete doctorDataOptional.doctor[key]
			}
		}

		for (let key in doctorDataOptional.user) {
			if (doctorDataOptional.user[key] === "" || doctorDataOptional.user[key] === this.props.initialFormData[key]) {
				delete doctorDataOptional.user[key]
			}
		}

		//console.log(this.state)
		//console.log(doctorDataOptional)

		if (this.state.fileInput.length !== 0) {
			userStore.updateDoctorAvatar( this.state.fileInput[0] )
				.then((res) => {
					if (res.ok) {
						this.props.enqueueSnackbar(
							`Аватарка успешно добавлена в конец списка аватаров!`,
							{ variant: 'success' }
						)
						this.props.updateUserData()
					} else {
						this.props.enqueueSnackbar(
							`ОШИБКА!`,
							{ variant: 'error' }
						)
					}
				})
		}

		if (Object.keys(doctorDataOptional.doctor).length === 0 && Object.keys(doctorDataOptional.user).length === 0) {
			this.props.enqueueSnackbar(
				`Текстовые данные не изменились!`,
				{ variant: 'info', }
			)
		} else {
			userStore.updateDoctor(doctorDataOptional)
				.then((result) => {
					// console.log(result)
					if (!result.ok) {
						this.props.enqueueSnackbar(
							`Ошибка при редактировании! ${result.message}`,
							{ variant: 'error', }
						)
					} else {
						this.props.enqueueSnackbar(
							`Редактирование успешно!`,
							{ variant: 'success', }
						)
						this.props.updateUserData()
					}
				})
		}
		this.props.closeDialog()
	}

	// createNewUser = () => {
	// 	let newDoctor = {
	// 		doctor: {
	// 			firstname: "Илья",
	// 			middlename: "Викторович",
	// 			lastname: "Загородний",
	// 			birthday: "2021-03-16 14:31:46.161000",
	// 			age: 30,
	// 			gender: 1,
	// 			info: "test"
	// 		},
	// 		user: {
	// 			login: "izagorodn",
	// 			password: "iliazagorodniy",
	// 			email: "izagorodn@gmail.com",
	// 			phone: "89673210055"
	// 		}
	// 	}
	// }

	render() {
		return (
			<Dialog>
				<DialogContent style={{display: "flex", padding: "36px 36px 8px", justifyContent: "center"}}>
					<div style={{width: "50%", paddingRight: 20, display: "flex", flexDirection: "column", justifyContent: "flex-start"}}>
						<div style={{color: "#00827c", padding: "0px 24px 36px 0", fontSize: "36px"}} id="form-dialog-title">Настройки пользователя</div>
						<AddPhotoDropzoneArea
							updateValue={this.handleFileChange}
							file={this.state.fileInput}
							avatar={this.props.initialFormData.avatar}
						/>
						<FormGroup column style={{padding: "36px 0 0 0", flexGrow: 1, justifyContent: "flex-end"}}>
							<FormControlLabel
								control={
									<Checkbox
										checked={this.state.checkboxIsDoctor}
					                    name="checkboxApp"
					                    color="primary"
									/>
								}
								label="Аккаунт принадлежит менеджеру"
							/>
							<FormControlLabel
								control={
									<Checkbox
										checked={this.state.checkboxIsOrganisation}
										onChange={this.handleCheckboxChange}
										name="checkboxSMS"
										color="primary"
									/>
								}
								label="Уведомлять при обнаружении события"
							/>
						</FormGroup>
					</div>

					<form onChange={this.validateForm} style={{width: "40%", height: "fit-content", display: "flex", flexDirection: "column", justifyContent: "flex-end", alignSelf: "center"}}>
						<div className="fio" style={{display: "flex", flexDirection: "column", marginBottom: 40}}>
							<TextFieldValidator
								required requiredValidation={this.state.checkIsRequiredFieldsFilled}
								fieldName="inputLastname" fieldLabel="Фамилия" fieldType={"text"}
								initialValue={this.props.initialFormData.lastname}
								validateChild={this.validateChild}
							/>

							<TextFieldValidator
								required requiredValidation={this.state.checkIsRequiredFieldsFilled}
								fieldName="inputFirstname" fieldLabel="Имя" fieldType={"text"}
								initialValue={this.props.initialFormData.firstname}
								validateChild={this.validateChild}
							/>

							<TextFieldValidator
								fieldName="inputMiddlename" fieldLabel="Отчество" fieldType={"text"}
								initialValue={this.props.initialFormData.middlename}
								validateChild={this.validateChild}
							/>
						</div>

						<div>
							<TextFieldValidator
								required requiredValidation={this.state.checkIsRequiredFieldsFilled}
								fieldName="inputLogin" fieldLabel="Логин" fieldType={"text"}
								initialValue={this.props.initialFormData.login}
								validateChild={this.validateChild}
							/>

							<TextFieldValidator
								fieldName="inputPassword" fieldLabel="Новый пароль" fieldType={"password"}
								validateChild={this.validateChild}
							/>
						</div>
					</form>
				</DialogContent>

				<DialogActions style={{margin: "48px 24px 16px 24px", justifyContent: "center"}}>
					<Button variant={"outlined"} onClick={this.props.closeDialog} color="primary">
						Отменить
					</Button>
					<Button variant={"contained"} color="primary" disabled={!this.state.isFormValidated} onClick={this.submitForm}>
						Изменить
					</Button>
				</DialogActions>
			</Dialog>
		);
	}
}

export default withSnackbar(UserDialogForm);
