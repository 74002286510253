import styled from "styled-components";
import right from '../../../static/img/icons-containers-36-px.png';
import left from '../../../static/img/icons-containers-36-px_2.png';


export const Wrapper = styled.div`
  background: #0c5891;
  min-height: 100vh;
`;

export  const useStyles = (theme) => ({
    root: {
        //flexGrow: 1,
        backgroundColor: '#0c5891',

    },
    appbar: {
        backgroundColor: '#0c5891',
        boxShadow: 'none',
    },
    buttonregistr: {
        cursor: 'pointer',
        boxShadow: 'none',
        margin: 8,
        fontFamily: 'Roboto',
        fontSize: 16,
        fontWeight: 500,
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.7,
        letterSpacing: 'normal',
        textAlign: 'center',
        color: '#fff',
        backgroundColor:'#f18c47',
        textTransform: 'none',
        float: 'right'
    },
    selectlanguage: {
        width: 120,
        color: 'white',
        lineHeight: 1,
        margin: '0 24px 0px 20px',
        padding: '3px 3px',
        borderRadius: 6,
        boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.07)',
        border: 'solid 1px #dfe3e5',
        '& .MuiInputBase-input': {
          padding: 0
        },
        '&:before' : {
            borderBottom:0
        },
        '&:hover:before' : {
            borderBottom:0
        }
    },
    footer:{
        backgroundColor: '#0c5891',
        color: 'white',
        padding: '16px 5px',
        '& a': {
            color: 'white',
            textDecoration: 'underline'
        },
        '& a:hover': {
            textDecoration: 'none'
        }
    }
});
