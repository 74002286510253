import styled from "styled-components";


export  const useStyles = (theme) => ({
    root: {
        flexGrow: 1
    },
    popupCookies: {
        position: 'fixed',
        left: '1%',
        right: 'auto',
        bottom: '50px',
        width: '98%',
        padding: '20px',
        borderRadius: '10px',
        background: '#9dc6d4',
        "z-index": 100000
    }
});
