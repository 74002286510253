import React,{Component} from "react";
import Box from '@material-ui/core/Box';

export default class Ru extends Component{


    render() {
        const {classes} = this.props;
        return <>
            <h1>ПОЛЬЗОВАТЕЛЬСКОЕ СОГЛАШЕНИЕ ("EULA")</h1>
            <Box component="p" m={1}>GritTec Ltd. ("GRITTEC") is willing to license the software identified below to you
                only upon the condition that you accept all of the terms contained in this software EULA. Please read the agreement carefully.
                By downloading or installing this software, you unconditionally accept the terms of the agreement.
            </Box>
            <Box component="p" m={1}>IF YOU DO NOT AGREE TO THE TERMS OF THIS EULA, GRITTEC IS UNWILLING TO LICENSE THE
                SOFTWARE PRODUCT TO YOU. IN SUCH EVENT, YOU MAY NOT USE SOFTWARE PRODUCT.
            </Box>
            <Box component="p" m={1}>The SOFTWARE PRODUCT is protected by the Russian Federation copyright laws and international
                copyright treaties, as well as other applicable national laws and treaties.
            </Box>
            <h2>SOFTWARE PRODUCT LICENSE</h2>
            <h2>1. DEFINITIONS.</h2>
            <Box component="p" m={3}>Software Product" or Application means the identified above in binary and source code form,
                any other human and machine readable materials (including, but not limited to, libraries, source files, header files,
                data files), documentation and any updates provided by GRITTEC to you by GRITTEC under this EULA.
                "End-User" means the legal entity or person who orders or downloads the SOFTWARE PRODUCT.
            </Box>
            <h2>2. LICENSE TO USE.</h2>
            <Box component="p" m={3}>
                Subject to the terms and conditions of this Agreement, GRITTEC grants you a non-exclusive, non-transferable, limited,
                worldwide license without license fees to reproduce and use internally Software complete and unmodified for the sole
                purpose of running it standalone or use as a part of your software or hardware. License does not give the right to
                redistribute, share the Software or its parts in any way without written permission from GRITTEC.
            </Box>
            <h2>3. RESTRICTIONS.</h2>
            <Box component="p" m={3}>
                You may not copy, duplicate, reproduce, license or sublicense the Software, or transfer or convey the
                Software or any right in the Software to anyone else without the prior written consent of GRITTEC;
                provided that You may make one copy of the Software for backup or archival purposes.
            </Box>
            <Box component="p" m={3}>
                You may not modify, reverse engineer, decompile, disassemble, adapt, translate to other languages, change
                the structure or make any changes in the SOFTWARE PRODUCT or any of its parts, as well as to develop derivative
                products on the basis of the SOFTWARE PRODUCT.
            </Box>
            <Box component="p" m={3}>
                You acknowledge that Licensed Software is not designed or intended for use in any mission critical designs,
                constructions, operation or maintenance. GRITTEC disclaims any express or implied warranty of fitness for such uses.
            </Box>
            <h2>4. SOFTWARE TRANSFER.</h2>
            <Box component="p" m={3}>
                You may not rent, lease, sublicense or lend the SOFTWARE PRODUCT. You may transfer the Software Product
                and license to another party if the other party agrees to accept the terms and conditions of this EULA.
                If you transfer the Software you must either transfer all copies, whether in printed or machine readable
                form to the same party. You have no right to transfer any of the Software parts for use by the third party.
            </Box>
            <h2>5. TRADEMARKS AND LOGOS.</h2>
            <Box component="p" m={3}>
                Copyright All title and copyrights in and to the SOFTWARE PRODUCT, the accompanying printed materials,
                and any copies of the SOFTWARE PRODUCT, are owned by GRITTEC. The SOFTWARE PRODUCT and all GRITTEC's trademarks,
                service marks, logos and other brand names and designations is protected by Russian federal laws and
                international treaty provisions. You may not copy the printed materials accompanying the SOFTWARE PRODUCT.
            </Box>
            <h2>6. GOVERNING LAW.</h2>
            <Box component="p" m={3}>
                This agreement shall be governed by the laws of Russian Federal and each party irrevocably submits to the
                exclusive jurisdiction of the Russian courts. No choice of law rules of any jurisdiction will apply.
            </Box>
            <h2>7. EVALUATION.</h2>
            <Box component="p" m={3}>
                GRITTEC may allow evaluating the SOFTWARE PRODUCT for free by downloading from the web-site or by another way.
                Receipt the SOFTWARE PRODUCT for evaluation means that you accept this EULA. Evaluation is limited to thirty (30)
                days trial period. After completion of the trial period, the agreement should be terminated and you should destroy
                all the copies of SOFTWARE PRODUCT.
            </Box>
            <h2>8. VALIDITY.</h2>
            <Box component="p" m={3}>
                This Agreement is valid unless terminated. GRITTEC has the right to terminate the license if the End-User
                has infringed any term of this Agreement. In this case you must return the purchased product to GRITTEC.
            </Box>
            <h2>9. LIMITED WARRANTY.</h2>
            <Box component="p" m={3}>
                GRITTEC warrants that the SOFTWARE PRODUCT will correspond with the accompanying written materials completely
                for a period of 30 (thirty) days from the date of receipt. In case of any defects, please inform GRITTEC.
                GRITTEC will correct the defects. Support of Software will be provided in English language via e-mail: support@grittec.com.
                Warranty is not applied for evaluation copies of SOFTWARE PRODUCT.
            </Box>
            <h2>10. DISCLAIMER OF WARRANTIES.</h2>
            <Box component="p" m={3}>
                EXCEPT AS EXPRESSLY STATED HEREIN, GRITTEC AND ITS SUPPLIERS MAKE NO WARRANTIES, EXPRESS, IMPLIED OR STATUTORY,
                AS TO ANY OTHER MATTERS, INCLUDING BUT NOT LIMITED TO NON-INFRINGEMENT OF THIRD PARTY RIGHTS, MERCHANTABILITY,
                OR FITNESS FOR ANY PARTICULAR PURPOSE. GRITTEC DO NOT AND CANNOT WARRANT THE PERFORMANCE OR RESULTS YOU MAY OBTAIN
                BY USING THE SOFTWARE.
            </Box>
            <h2>11. LIMITATION OF LIABILITY.</h2>
            <Box component="p" m={3}>
                Neither GRITTEC nor anyone else who has been involved in the creation, production, or delivery of GRITTEC's Products
                which are the subject of this Agreement shall be liable for any indirect, consequential or incidental damages
                (including damages for loss of business profits, business interruption, loss of business information, and the like)
                arising out of the use or inability to use GRITTEC's Products even if GRITTEC has been advised of the possibility
                of such damages.
            </Box>
            <h2>12. SOURCE CODE.</h2>
            <Box component="p" m={3}>
                Software may contain source code that, unless expressly licensed for other purposes, is provided solely
                for reference purposes pursuant to the terms of this Agreement. Source code may not be redistributed unless
                expressly provided for in this Agreement.
            </Box>
            <h2>13. THIRD PARTY CODE.</h2>
            <Box component="p" m={3}>
                GRITTEC guarantees that SOFTWARE does not contain any third party code.
                The disclaimer of warranty and limitation of liability provisions in paragraphs 9 and 10 shall apply to all Software.
            </Box>
            <h2>14. INSTALLATION AND UPDATE.</h2>
            <Box component="p" m={3}>
                The SOFTWARE PRODUCT installation and update processes may cause transmitting a limited amount of data to GRITTEC
                (or its service provider) about those specific processes to help GRITTEC understand and optimize them.
                GRITTEC does not associate the data with personally identifiable information. Customer's purchase entitles
                to free upgrades for the entire 1.xx series, and allows Customer to run beta versions.
            </Box>
            <h2>15. TERMINATION.</h2>
            <Box component="p" m={3}>
                This Agreement is valid until terminated. You may terminate this Agreement at any time by destroying all
                its copies of Software. GRITTEC may terminate this Agreement immediately without notice from GRITTEC
                if you fail to comply with any provision of this Agreement. Upon Termination, you must destroy all its copies of Software.
            </Box>
            <h2>16. EXPORT REGULATIONS.</h2>
            <Box component="p" m={3}>
                Software delivered under this Agreement is subject to Russian export regulations and may be subject to export
                or import regulations in other countries. You agree to comply strictly with all such laws and regulations
                and acknowledge that you have the responsibility to obtain such licenses to export, re-export,
                or import as may be required after delivery to you.
            </Box>
            <h2>17. SEVERABILITY.</h2>
            <Box component="p" m={3}>
                Should any part of this Agreement be declared to be void or invalid by the final decision of any court of
                competent jurisdiction, the remainder of this Agreement shall continue to be in force between the parties,
                as if the portion which has been declared invalid or void was excluded from the Agreement at commencement thereof.
            </Box>
            <h2>18. INTEGRATION.</h2>
            <Box component="p" m={3}>
                This Agreement is the entire agreement between you and GRITTEC relating to its subject matter.
                It supersedes all prior or contemporaneous oral or written communications, proposals, representations
                and warranties and prevails over any conflicting or additional terms of any quote, order, acknowledgment,
                or other communication between the parties relating to its subject matter during the term of this Agreement.
                No modification of this Agreement will be binding, unless in writing and signed by an authorized representative of each party.
            </Box>
            <h2>19. FORCE MAJEURE.</h2>
            <Box component="p" m={3}>
                Neither party shall be responsible for any failure to perform any obligation or provide service hereunder
                because of any Act of God, strike, work stoppage, governmental acts or directives, war, riot or civil commotion,
                equipment or facilities shortages or other similar force beyond such party's reasonable control.
            </Box>
            <h2>20. SERVICE OF NOTICES.</h2>
            <Box component="p" m={3}>
                Any notice to be given hereunder shall be in English language and shall be sufficiently given if forwarded by e-mail
                and confirmed by registered post, or hand or courier delivery, to the last known corresponding address
                or number of the receiving party.
            </Box>
            <Box component="p" m={1}>
                Updated: 2020.05.20
            </Box>
        </>;
    }
}

