import React,{Component} from "react";
import Grid from '@material-ui/core/Grid';
import { NotificationManager } from 'react-notifications';
import { withStyles } from '@material-ui/core/styles';
import {UserContext} from "../../context/UserContext";
import Container from '@material-ui/core/Container';
import ColleaguelList from '../../components/ColleagueList';
import {StyledTableCell, StyledTableRow, useStyles} from './styles';
import apiStore from '../../store/ApiStore';
import Client from '../../store/Model/Client';
import 'moment/locale/ru';
// moment.locale('ru');

class Colleagues extends Component{

    constructor(props) {
        super(props);
        this.state = {
            tabIndex: 0,
            arClients: [],
            selectUser: null,
            page: 0,
            pageSize: 10,
            enroll: [],
            arUsers: []
        };
    }

/*    loadList = async () => {
        await apiStore.getClients(0, 100).then(data => data.data).then(data => data.map(val=>new Client(val))).then(data => this.setState({arUsers: data}));
    } */

    componentDidMount() {
     //   this.loadList();
    }

    render() {
        let self = this;
        return <UserContext.Consumer>
            {
                ({user}) => self._render()
            }
        </UserContext.Consumer>;
    }

    _render() {
        const { classes } = this.props;

        return (<div className={classes.root} style={{minHeight: "100vh"}}>
            <Container>
                <Grid container spacing={0} className={classes.find}  >
                    {this.state.select?
                        <>
                            <Grid container spacing={0} className={classes.find}  >
                                <Grid item xs={12} md={12} lg={12} >

                                </Grid>
                            </Grid>
                        </>:
                        ''
                    }
                </Grid>
                <Grid container spacing={0} style={{}} >
                    <Grid item xs={12} md={12} lg={12} style={{backgroundColor: '#fff',
                        paddingTop: 24,
                        paddingBottom: 54,
                        paddingLeft: 12,
                        paddingRight: 12}}
                    >
                        <ColleaguelList
                            onSelectUser={async (data) => {
                                let uid = data.user_u;//data.pop();
                                let client = [];
                                let enrol = [];
                                this.setState({select: data, enrol: enrol});
                            }}
                        />
                    </Grid>
                </Grid>
            </Container>
        </div>);
    }
}

export default withStyles(useStyles)(Colleagues)
