import React, {Component} from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import { DropzoneArea } from 'material-ui-dropzone'
import PropTypes from "prop-types";
import TextField from "@material-ui/core/TextField/TextField";
import Grid from "@material-ui/core/Grid";
import moment from "moment";
import 'moment/locale/ru';
import Alert from '@material-ui/lab/Alert';
import {Trans} from "react-i18next";
import apiStore from "../../../store/ApiStore";
import {NotificationManager} from "react-notifications";
import userStore from "../../../store/UserStore";
moment.locale('ru');

export default class DialogFeedBack extends Component {
  constructor(props){
    super(props);
    this.state = {
      comments: null,

      errorRegistr: [],
      confirmIsSuccess: null,
      firstname: null,
      lastname: null,
      email: null,
      question: null,
      errors: {
        firstname: null,
        lastname: null,
        email: null,
        question: null
      }
    };
  }

  componentWillReceiveProps(): void{
    // console.log('componentWillReceiveProps');
    //this.setState({
    //  question: this.props.question
    //});
  };

  componentDidMount() {
    let info = userStore.getUserInfo()['manager'];
    info['email'] = userStore.getUserInfo()['email'];
    info['phone'] = userStore.getUserInfo()['phone'];
    //console.log("componentDidMount, info: ", info);
    this.setState(info);
  };

  formValid = (event) => {
    let valid = true;
    let checkInput = [
      'firstname',
      'lastname',
      'email',
      'question'
    ];
    for(let key of checkInput){
      if(!this.state[key]){
        valid = false;
      }
    }
    // console.log(this.state);
    // console.log(valid);
    return valid;
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const regFormData = {
        firstname: this.state.firstname,
        lastname: this.state.lastname,
        email: this.state.email,
        question: this.state.question
    };
    apiStore.sendSupport(this.state.question, this.state.email).then((data) => {
      //console.log(data);
      if(data.statusCode>300){
        NotificationManager.error(data.error, <Trans i18nKey={"SentMessageError"} >{"SentMessageError"}</Trans>);
      } else {
        NotificationManager.success(<Trans i18nKey={"SentMessage"} >{"SentMessage"}</Trans>);
        this.setState({
          firstname: null,
          lastname: null,
          email: null,
          question: null
        });
        // close form
        this.props.handleClose();
      }
    })
    // console.log(regFormData);
  };

  textChange = (event) => {
    let val = {};
    val[event.target.name] = event.target.value;
    // console.log(val);
    this.setState(val);
  };

  render() {
    return (
        <>
          <Dialog
              open={this.props.isOpen}
              onClose={this.props.handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
          >
            {/*<form onSubmit={(event) => {
              event.preventDefault();
              this.props.handleSave(this.state);
            }}>*/}
              <form onSubmit={this.handleSubmit}>
              <DialogTitle id="alert-dialog-title"><Trans i18nKey={"Feedback"} >{"Feedback"}</Trans></DialogTitle>
              <DialogContent style={{width:540}}>
                <DialogContentText id="alert-dialog-description">
                  {this.props.subtitle}
                </DialogContentText>
                {this.props.errorUpload ? <Alert severity="error" style={{marginBottom: 25}} >{this.props.errorUpload}</Alert>:''}
                <div style={{marginBottom: 10}}>
                  <b><Trans i18nKey={"FirstName"} >FirstName</Trans>*</b>
                </div>
                <TextField
                    required
                    disabled
                    error={this.state.errorRegistr['firstname']}
                    helperText={this.state.errorRegistr['firstname']}
                    onChange={this.textChange}
                    value={this.state.firstname}
                    style={{marginBottom: 25}}
                    variant="outlined"
                    id = "firstname"
                    name = "firstname"
                    label = {<Trans i18nKey={"FirstName"} >FirstName</Trans>}
                    type = "text"
                    fullWidth
                />
                <div style={{marginBottom: 10}}>
                  <b><Trans i18nKey={"LastName"} >LastName</Trans>*</b>
                </div>
                <TextField
                    required
                    disabled
                    error={this.state.errorRegistr['lastname']}
                    helperText={this.state.errorRegistr['lastname']}
                    onChange={this.textChange}
                    value={this.state.lastname}
                    style={{marginBottom: 25}}
                    variant="outlined"
                    id = "lastname"
                    name = "lastname"
                    label = {<Trans i18nKey={"LastName"} >LastName</Trans>}
                    type = "text"
                    fullWidth
                />
                <div style={{marginBottom: 10}}>
                  <b><Trans i18nKey={"WorkEmail"} >WorkEmail</Trans>*</b>
                </div>
                <TextField
                    required
                    error={this.state.errorRegistr['email']}
                    helperText={this.state.errorRegistr['email']}
                    onChange={this.textChange}
                    value={this.state.email}
                    style={{marginBottom: 25}}
                    variant="outlined"
                    id = "email"
                    name = "email"
                    label={<Trans i18nKey={"Email"} >{"Email"}</Trans>}
                    type = "text"
                    fullWidth
                />
                <div style={{marginBottom: 10}}>
                  <b><Trans i18nKey={"Message"} >Message</Trans>*</b>
                </div>
                <TextField
                    multiline={true}
                    onChange={(event) => this.setState({question: event.target.value}) }
                    style={{marginBottom: 25}}
                    minRows="6"
                    maxRows="6"
                    Rows="6"
                    variant="outlined"
                    id = "question"
                    name = "question"
                    label = {<Trans i18nKey={"Message"} >{"Message"}</Trans>}
                    type = "text"
                    fullWidth
                />

              </DialogContent>
              <DialogActions>
                <Button variant="outlined" color="secondary"  onClick={(event) => this.props.handleClose()} >
                  <Trans i18nKey={"Close"} >{"Close"}</Trans>
                </Button>

                <Button variant="outlined" type="submit" color="primary" >
                  <Trans i18nKey={"Send"} >{"Send"}</Trans>
                </Button>
              </DialogActions>
            </form>
          </Dialog>
        </>
    );
  }


}

DialogFeedBack.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleSave: PropTypes.func.isRequired,
  title: PropTypes.string,
  subtitle: PropTypes.string,
};
