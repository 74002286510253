import React,{Component} from "react";
import PropTypes from "prop-types";
import { Wrapper } from "./styles";
import {Link} from "react-router-dom";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Button from '@material-ui/core/Button';
import {createTheme, withStyles} from "@material-ui/core/styles";
import {useStyles} from "./styles";
import SelectLang from '../../../components/shared/SelectLang/SelectLang';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
import {Trans} from "react-i18next";
import logo from '../../../static/img/logos-color.svg';
import {UserContext} from "../../../context/UserContext";





class PublicLayout extends Component  {


    render() {
        const {classes} = this.props;
        return <UserContext.Consumer>
            {({user, setAuthUser, signOut, showSettings}) => (
                <Wrapper>
                    <AppBar position="static"  spacing={0} style={{backgroundColor: '#FFF'}} >
                            <Container>
                                <Grid container spacing={0} >
                                    <Grid item xs={5} md={9} lg={9} >
                                        <Link to="/"><img src={logo}  alt={"logo"} style={{margin: 10}} /></Link>
                                    </Grid>
                                    <Grid item xs={4} md={"auto"} lg={"auto"} sx={{ display: { xs: 'none', md: 'flex' } }} >
                                        <Link to="/login" ><Button className={classes.buttonenter}  variant="contained" ><Trans i18nKey={"SignIn"} >{"SignIn"}</Trans></Button></Link>
                                    </Grid>
                                    <Grid item xs={3} md={"auto"} lg={"auto"}  sx={{ display: { xs: 'none', md: 'flex' } }} className={"wrapperSelectLang"} >
                                        <SelectLang />
                                    </Grid>
                                </Grid>
                            </Container>
                    </AppBar>
                    {this.props.children}
                    <footer className={classes.footer}>
                        <Container>
                            <Grid container spacing={2} >
                                <Grid item xs={2} md={"auto"} lg={"auto"} ><Trans i18nKey={"Copyright"} >{"Copyright"}</Trans></Grid>
                                <Grid item xs={2} lg={2} ></Grid>
                                <Grid item xs={2} md={"auto"} lg={"auto"} style={{textAlign:'right'}} >
                                    <Link to="/privacy-policy" style={{marginRight:10}} ><Trans i18nKey={"Privacypolicy"} >{"Privacypolicy"}</Trans></Link>
                                    <Link to="/user-agreement" style={{marginRight:10}} ><Trans i18nKey={"Useragreement"} >{"Useragreement"}</Trans></Link>
                                    <Link to="/cookies-policy"><Trans i18nKey={"CookiesPolicy"} >{"CookiesPolicy"}</Trans></Link>
                                </Grid>
                            </Grid>
                        </Container>
                    </footer>
                </Wrapper>
            )}
        </UserContext.Consumer>;
    };
}

PublicLayout.propTypes = {
    children: PropTypes.element.isRequired
};

export default withStyles(useStyles)(PublicLayout)
